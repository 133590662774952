import React from 'react'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    NavLink
  } from "react-router-dom";
import { Auth } from 'aws-amplify';

async function signOut() {
  try {
      await Auth.signOut();
      window.location.reload();
  } catch (error) {
      console.log('error signing out: ', error);
  }
}

export const Admin = ({ children, user }) => {

    return (
      <div>
        <nav class="sidenav navbar navbar-vertical  fixed-left  navbar-expand-xs navbar-light bg-white" id="sidenav-main">
          <div class="scrollbar-inner">
            <div class="sidenav-header  align-items-center">
                <img src="../assets/img/brand/indi.png" class="icon-image" alt="..." />
            </div>
            <div class="navbar-inner">
              <div class="collapse navbar-collapse" id="sidenav-collapse-main">
                <ul class="navbar-nav">
                <li class="nav-item">
                    <NavLink to="/users" className={isActive =>"nav-link" + (!isActive ? " unselected" : "")}>
                      <i class="ni ni-single-02 text-yellow"></i>
                      <span class="nav-link-text">Users</span>
                    </NavLink>
                  </li>
                  <li class="nav-item">
                    <NavLink to="/cupons" className={isActive =>"nav-link" + (!isActive ? " unselected" : "")}>
                      <i class="ni ni-collection text-red"></i>
                      <span class="nav-link-text">Cupons</span>
                    </NavLink>
                  </li>
                  <li class="nav-item">
                    <NavLink to="/products" className={isActive =>"nav-link" + (!isActive ? " unselected" : "")}>
                      <i class="ni ni-tag text-purple"></i>
                      <span class="nav-link-text">Products</span>
                    </NavLink>
                  </li>                                    
                  <li class="nav-item">
                    <NavLink to="/orders" className={isActive =>"nav-link" + (!isActive ? " unselected" : "")}>
                      <i class="ni ni-single-copy-04 text-orange"></i>
                      <span class="nav-link-text">Orders</span>
                    </NavLink>
                  </li>                                                      
                  <li class="nav-item">
                    <NavLink to="/platforms" className={isActive =>"nav-link" + (!isActive ? " unselected" : "")}>
                      <i class="ni ni-button-power text-green"></i>
                      <span class="nav-link-text">Platforms</span>
                    </NavLink>
                  </li>
                  <li class="nav-item">
                    <NavLink to="/modules" className={isActive =>"nav-link" + (!isActive ? " unselected" : "")}>
                      <i class="pi pi-server text-gray"></i>
                      <span class="nav-link-text">Modules</span>
                    </NavLink>
                  </li>                  
                  <li class="nav-item">
                    <NavLink to="/connections" className={isActive =>"nav-link" + (!isActive ? " unselected" : "")}>
                      <i class="pi pi-sort-alt-slash text-blue"></i>
                      <span class="nav-link-text">Connections</span>
                      </NavLink>
                  </li>                                    
                  <li class="nav-item">
                    <NavLink to="/db" className={isActive =>"nav-link" + (!isActive ? " unselected" : "")}>
                      <i class="pi pi-database text-blue"></i>
                      <span class="nav-link-text">Database</span>
                      </NavLink>
                  </li>                                    
                </ul>
                <hr class="my-3" />
                <h6 class="navbar-heading p-0 text-muted">
                  <span class="docs-normal"></span>
                </h6>
                <ul class="navbar-nav mb-md-3">
                  <li class="nav-item">
                  <a class="nav-link" onClick={signOut} href="javascript:void(0)">
                      <i class="ni ni-spaceship"></i>
                      <span class="nav-link-text">Logout</span>
                  </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>
        <div class="main-content" id="panel">
          <nav class="navbar navbar-top navbar-expand navbar-dark bg-primary border-bottom">
            <div class="container-fluid">
              <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav align-items-center  ml-auto ml-md-0 ">
                  <li class="nav-item dropdown">
                    <a class="nav-link pr-0" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <div class="media align-items-center">
                        <span class="avatar avatar-sm rounded-circle">
                          <img src={user && user.attributes.image} onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'}  alt="image" className="profile-image"/>
                        </span>
                        <div class="media-body  ml-2  d-none d-lg-block">
                          <span class="mb-0 text-sm  font-weight-bold">{user && user.attributes.email}</span>
                        </div>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
          <div class="container-fluid mt-4">
            {children}
          </div>
        </div>
      </div>
    )
}
