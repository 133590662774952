import React, { useState, useEffect, useRef } from 'react';
import { API, Auth, graphqlOperation } from 'aws-amplify'
import { byWorkerByDate, getWorker, listPlatformProfiles, listWorkers } from '../graphql/queries';
import { createWorker, saveNotifications, updateWorker } from '../graphql/mutations';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import moment from 'moment'
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { InputSwitch } from 'primereact/inputswitch';
import { Link } from "react-router-dom";
import { Dropdown } from 'primereact/dropdown';
import { Toolbar } from 'primereact/toolbar';
import { InputNumber } from 'primereact/inputnumber';

function Users() {

    const empty_user = {
        _version: null,
        id: null,
        is_subscribed: null,
        last_payment_date: null,
        next_payment_date: null,
        worker_kyc: null,
        birthday: null,
    };

    let empty_kyc = {
        type: '',
        first_names: '',
        last_names: '',
        id_type: '',
        id_number: '',
        birth_country: '',
        birthday: null,
        gender: '',
        civil_status: '',
        email: '',
        phone_number: '',
        address: '',
        type: '',
        percentage: '',
    };

    const [editDialog, setEditDialog] = useState(false);
    const [pushDialog, setPushDialog] = useState(false);
    const [users, setUsers] = useState(null);
    const [user, setUser] = useState(empty_user);
    const [worker_kyc1, setWorker_kyc1] = useState(empty_kyc);
    const [worker_kyc2, setWorker_kyc2] = useState(empty_kyc);
    const [worker_kyc3, setWorker_kyc3] = useState(empty_kyc);
    const [pushTitle, setPushTitle] = useState('');
    const [pushSubtitle, setPushSubtitle] = useState('');
    const [pushMessage, setPushMessage] = useState('');
    const [pushType, setPushType] = useState(null);
    const [pushRoute, setPushRoute] = useState(null);
    const [pushSubroute, setPushSubroute] = useState('');
    const [globalFilter, setGlobalFilter] = useState(null);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [unconfirmed, setUnconfirmed] = useState([]);
    const [issue, setIssue] = useState([]);
    const toast = useRef(null);
    const dt = useRef(null);
    const [exportable, setExportable] = useState(false);

    useEffect(() => {
        fetchUsers();
    }, []);

    async function fetchUsers() {
        try {
            let nextUserToken = '';
            let allUsers = [];
            while (nextUserToken != null) {
                console.log(nextUserToken,"protoken");
                const usersData = nextUserToken? await API.graphql(graphqlOperation(listWorkers, { limit: 1000, nextToken: nextUserToken })) : await API.graphql(graphqlOperation(listWorkers, { limit: 1000 }));
                const _users = usersData.data.listWorkers.items;
                nextUserToken = usersData.data.listWorkers.nextToken;
                console.log(nextUserToken,"postoken");
                for (var index in _users) {
                    try {
                        _users[index].createdAtFormat = dateFormat(_users[index].createdAt);
                        //const worker = await API.graphql(graphqlOperation(getWorker, { id: _users[index].id }));
                        /*
                        if (_users[index].is_subscribed === null) {
                            _users[index].is_subscribed = "false";
                        } else {
                            _users[index].is_subscribed = _users[index].is_subscribed.toString();
                        }
                        _users[index].next_payment_date = dateFormat(_users[index].next_payment_date);
                        _users[index].last_payment_date = dateFormat(_users[index].last_payment_date);
    
                        //_users[index].birthday = dateFormat(worker.data.getWorker.birthday);
                        if (worker.data.getWorker.worker_kyc != null && worker.data.getWorker.worker_kyc[1] != undefined) {
                            //console.log("u",worker.data.getWorker.worker_kyc[1])
                            _users[index].id_type = worker.data.getWorker.worker_kyc[0].id_type ? worker.data.getWorker.worker_kyc[0].id_type : "";
                            _users[index].id_number = worker.data.getWorker.worker_kyc[0].id_number ? worker.data.getWorker.worker_kyc[0].id_number : "";
                            _users[index].id_type_b1 = worker.data.getWorker.worker_kyc[1].id_type ? worker.data.getWorker.worker_kyc[1].id_type : "";
                            _users[index].birthday_b1 = worker.data.getWorker.worker_kyc[1].birthday ? dateFormat(worker.data.getWorker.worker_kyc[1].birthday) : "";
                            _users[index].birth_country_b1 = worker.data.getWorker.worker_kyc[1].birth_country ? worker.data.getWorker.worker_kyc[1].birth_country : "";
                            _users[index].id_number_b1 = worker.data.getWorker.worker_kyc[1].id_number ? worker.data.getWorker.worker_kyc[1].id_number : "";
                            _users[index].full_name_b1 = worker.data.getWorker.worker_kyc[1].first_names && worker.data.getWorker.worker_kyc[1].last_names ? worker.data.getWorker.worker_kyc[1].first_names + " " + worker.data.getWorker.worker_kyc[1].last_names : "";
                            _users[index].phone_number_b1 = worker.data.getWorker.worker_kyc[1].phone_number ? worker.data.getWorker.worker_kyc[1].phone_number : "";
                            _users[index].percentage_b1 = worker.data.getWorker.worker_kyc[1].percentage ? String(worker.data.getWorker.worker_kyc[1].percentage) : "";
                            _users[index].gender = worker.data.getWorker.worker_kyc[0].gender ? worker.data.getWorker.worker_kyc[0].gender : "";
                            _users[index].civil_status = worker.data.getWorker.worker_kyc[0].civil_status ? worker.data.getWorker.worker_kyc[0].civil_status : "";
                            _users[index].address = worker.data.getWorker.worker_kyc[0].address ? worker.data.getWorker.worker_kyc[0].address : "";
                            if (worker.data.getWorker.worker_kyc.length === 3 && worker.data.getWorker.worker_kyc[2] != undefined) {
                                _users[index].id_type_b2 = worker.data.getWorker.worker_kyc[2].id_type ? worker.data.getWorker.worker_kyc[2].id_type : "";
                                _users[index].birthday_b2 = worker.data.getWorker.worker_kyc[2].birthday ? dateFormat(worker.data.getWorker.worker_kyc[2].birthday) : "";
                                _users[index].birth_country_b2 = worker.data.getWorker.worker_kyc[2].birth_country ? worker.data.getWorker.worker_kyc[2].birth_country : "";
                                _users[index].id_number_b2 = worker.data.getWorker.worker_kyc[2].id_number ? worker.data.getWorker.worker_kyc[2].id_number : "";
                                _users[index].full_name_b2 = worker.data.getWorker.worker_kyc[2].first_names && worker.data.getWorker.worker_kyc[2].last_names ? worker.data.getWorker.worker_kyc[2].first_names + " " + worker.data.getWorker.worker_kyc[2].last_names : "";
                                _users[index].phone_number_b2 = worker.data.getWorker.worker_kyc[2].phone_number ? worker.data.getWorker.worker_kyc[2].phone_number : "";
                                _users[index].percentage_b2 = worker.data.getWorker.worker_kyc[2].percentage ? String(worker.data.getWorker.worker_kyc[2].percentage) : "";
                            }
                        }
                        
                        try {
                            const platforms = await API.graphql(graphqlOperation(listPlatformProfiles, { filter: { worker_id: { eq: _users[index].id } }, limit: 1000 }));
                            if (platforms.data.listPlatformProfiles.items.length > 0) {
                                _users[index].platforms = platforms.data.listPlatformProfiles.items;
                            }
                        } catch (err) {
                            console.log('error getting platforms', err);
                        }
                        */

                    } catch (err) {
                        console.log(err);
                    }
                }
                allUsers = [...allUsers, ..._users];
            }



            console.log(allUsers, "users");

            setUsers(allUsers);

            let nextToken = '';
            let cognitoUsers = [];
            while (nextToken !== null) {
                if (nextToken === '') {
                    const res = await API.get('AdminQueries', '/listUsers?limit=60', {
                        headers: {
                            Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
                        }
                    });
                    if (res.Users) {
                        cognitoUsers = [...res.Users, ...cognitoUsers];
                    }
                    //console.log(res.NextToken);
                    if (res.NextToken) {
                        nextToken = res.NextToken;
                    }
                } else {
                    const res = await API.get('AdminQueries', `/listUsers?limit=60&token=${encodeURIComponent(nextToken)}`, {
                        headers: {
                            Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
                        }
                    });

                    if (res.Users) {
                        cognitoUsers = [...res.Users, ...cognitoUsers];
                    }
                    if (res.NextToken) {
                        nextToken = res.NextToken;
                    } else {
                        nextToken = null
                    }
                }

            }

            const unconfirmedUsers = cognitoUsers.filter(u => u.UserStatus === "UNCONFIRMED");
            const confirmedUsers = cognitoUsers.filter(u => u.UserStatus === "CONFIRMED");
            const issueUsers = confirmedUsers.filter(cu => !allUsers.some(u => u.id === cu.Username));

            console.log(issueUsers);

            setUnconfirmed(unconfirmedUsers.map(u => {
                u.Name = u.Attributes.find(a => a.Name === 'given_name')?.Value;
                u.Last = u.Attributes.find(a => a.Name === 'family_name')?.Value;
                u.Email = u.Attributes.find(a => a.Name === 'email')?.Value;
                u.Phone = u.Attributes.find(a => a.Name === 'phone_number')?.Value;
                return u;
            }))
            setIssue(issueUsers.map(u => {
                u.Name = u.Attributes.find(a => a.Name === 'given_name')?.Value;
                u.Last = u.Attributes.find(a => a.Name === 'family_name')?.Value;
                u.Email = u.Attributes.find(a => a.Name === 'email')?.Value;
                u.Phone = u.Attributes.find(a => a.Name === 'phone_number')?.Value;
                u.Username = u.Username
                return u;
            }))
        } catch (err) {
            console.log(err);
            console.log('error fetching users');
        }
    }


    const hideDialog = () => {
        setEditDialog(false);
    }

    const hidePushDialog = () => {
        setPushDialog(false);
    }


    async function updateUser() {
        try {
            worker_kyc2.percentage = parseFloat(worker_kyc2.percentage);
            worker_kyc3.percentage = parseFloat(worker_kyc3.percentage);
            delete worker_kyc1.percentage;
            let _user = {
                ...user,
                worker_kyc: [worker_kyc1, worker_kyc2, worker_kyc3]
            };
            console.log(_user);
            await API.graphql(graphqlOperation(updateWorker, { input: _user }));

            setUser(empty_user);
            setWorker_kyc1({ ...empty_kyc, type: "insured" });
            setWorker_kyc2({ ...empty_kyc, type: "recipient" });
            setWorker_kyc3({ ...empty_kyc, type: "recipient" });

            fetchUsers();
            setEditDialog(false);

            //const worker=await API.graphql(graphqlOperation(getWorker, {id: _user.id}));
            //console.log(worker.data.getWorker);
        } catch (err) {
            console.log(err)
            console.log('error updating user', err)
        }

    }

    async function createIssueWorker(user) {
        const worker = await API.graphql(graphqlOperation(createWorker, {
            input: {
                id: user.Username,
                given_name: user.Name,
                family_name: user.Last,
                phone_number: user.Phone,
                email: user.Email
            }
        }));
        console.log("worker", worker.data);
    }

    async function editUser(user) {
        const worker = await API.graphql(graphqlOperation(getWorker, { id: user.id }));
        console.log("worker", worker.data);
        let _user = {
            _version: worker.data.getWorker._version,
            id: worker.data.getWorker.id,
            is_subscribed: worker.data.getWorker.is_subscribed,
            last_payment_date: worker.data.getWorker.last_payment_date,
            next_payment_date: worker.data.getWorker.next_payment_date,
            birthday: worker.data.getWorker.birthday,
            worker_kyc: worker.data.getWorker.worker_kyc,
            promoter_id: worker.data.getWorker.promoter_id,
        };
        setUser(_user);
        let worker_kyc = _user.worker_kyc || [empty_kyc, empty_kyc, empty_kyc];
        if (worker_kyc.length === 1) worker_kyc.push(empty_kyc);
        if (worker_kyc.length === 2) worker_kyc.push(empty_kyc);
        setWorker_kyc1({ ...worker_kyc[0], type: "insured" });
        setWorker_kyc2({ ...worker_kyc[1], type: "recipient" });
        setWorker_kyc3({ ...worker_kyc[2], type: "recipient" });
        setEditDialog(true);
    }

    const dateFormat = (date) => {
        if (typeof date === "undefined" || date === null) return date;
        return moment(date).format('DD/MM/YYYY')
    }

    const onChangeUser = (e, name) => {
        let val = e.value;
        if (typeof val != "boolean") {
            const date = new Date(val).toISOString();
            val = date;
        }
        let _user = { ...user };
        _user[`${name}`] = val;
        setUser(_user);
    }

    const onChangeKYC1 = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _kyc = { ...worker_kyc1 };
        _kyc[`${name}`] = val;
        setWorker_kyc1(_kyc);
    }
    const onChangeKYC2 = (e, name) => {
        const val = (e.target && e.target.value || e.value) || '';
        let _kyc = { ...worker_kyc2 };
        _kyc[`${name}`] = val;
        console.log(_kyc);
        setWorker_kyc2(_kyc);
    }

    const onChangeKYC3 = (e, name) => {
        const val = (e.target && e.target.value || e.value) || '';
        let _kyc = { ...worker_kyc3 };
        _kyc[`${name}`] = val;
        setWorker_kyc3(_kyc);
    }

    const onChangeDateUser = (e, name) => {
        let val = (e.target && e.target.value) || '';
        const date = new Date(val).toISOString().split('T')[0];
        let _user = { ...user };
        _user[`${name}`] = date;
        setUser(_user);
    }

    const onChangeDateKYC2 = (e, name) => {
        let val = (e.target && e.target.value) || '';
        let _kyc = { ...worker_kyc2 };
        const date = new Date(val).toISOString().split('T')[0];
        _kyc[`${name}`] = date;
        setWorker_kyc2(_kyc);
    }

    const onChangeDateKYC3 = (e, name) => {
        let val = (e.target && e.target.value) || '';
        let _kyc = { ...worker_kyc3 };
        const date = new Date(val).toISOString().split('T')[0];
        _kyc[`${name}`] = date;
        setWorker_kyc3(_kyc);
    }



    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button icon="pi pi-pencil" className="p-button-rounded  mr-2 bg-primary" onClick={() => editUser(rowData)} />
                <Link to={`/individualEarnings/${rowData.id}`}>
                    <Button icon="pi pi-dollar" className="p-button-rounded p-button-success" />
                </Link>
            </React.Fragment>
        );
    }

    const issueBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <Button icon="pi pi-save" className="p-button-rounded  mr-2 bg-primary" onClick={() => createIssueWorker(rowData)} />
            </React.Fragment>
        );
    }

    const sendPushNotificationToUsers = async () => {
        try {
            console.log(selectedUsers);
            const ids = selectedUsers.map(user => user.id);
            console.log({ worker_ids: ids, title: pushTitle, subtitle: pushSubtitle, message: pushMessage, send_push: true, type: pushType, sub_route: pushSubroute, route: pushRoute });
            const res = await API.graphql(graphqlOperation(saveNotifications, {
                worker_ids: ids,
                title: pushTitle,
                subtitle: pushSubtitle,
                message: pushMessage,
                send_push: true,
                type: pushType,
                route: pushRoute,
                sub_route: pushSubroute
            }));
            console.log(res);
            setPushDialog(false);
            setPushTitle('');
            setPushSubtitle('');
            setPushMessage('');
            setPushType(null);
            setPushRoute(null);
            setPushSubroute('');
        } catch (err) { Toast.error(err.message); }
    }

    const header = (
        <div className="table-header">
            <h3>Users</h3>
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span>
        </div>
    );

    const productDialogFooter = (
        <React.Fragment>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text c-primary" onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text c-primary" onClick={() => updateUser()} />
        </React.Fragment>
    );
    const exportCSV = () => {
        dt.current.exportCSV();
    }
    const rightToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />
            </React.Fragment>
        )
    }

    const leftToolbarTemplate = () => {
        return (
            <React.Fragment>
                <Button className='p-button bg-primary mr-2' label='Send Push Notification' onClick={() => setPushDialog(true)} disabled={selectedUsers.length === 0}></Button>
            </React.Fragment>
        )
    }

    const typeOptions = [{
        label: 'Health',
        value: 'health'
    }, {
        label: 'Money',
        value: 'money'
    }, {
        label: 'Alert',
        value: 'alert'
    }, {
        label: 'Other',
        value: 'other'
    }];

    const routeOptions = [{
        label: 'Insurance',
        value: 'INSURANCE'
    }, {
        label: 'Platforms',
        value: 'PLATFORMS'
    }, {
        label: 'Cupons',
        value: 'CUPONS'
    }, {
        label: 'Casas',
        value: 'SPOTS'
    },
    {
        label: 'Mircrocreditos',
        value: 'CREDITS'
    },
    {
        label: 'Productos',
        value: 'PRODUCTS'
    },
    {
        label: 'Ajustes',
        value: 'SETTINGS'
    },];


    return (
        <div className="datatable-crud-demo">
            <Toast ref={toast} />

            <div className="card">
                <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
                <DataTable ref={dt} value={users}
                    dataKey="id" paginator rows={10} rowsPerPageOptions={[10, 25, 50]}
                    paginatorTemplate="PrevPageLink PageLinks NextPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} users"
                    globalFilter={globalFilter} header={header} responsiveLayout="scroll" selection={selectedUsers} onSelectionChange={e => setSelectedUsers(e.value)}>
                    <Column selectionMode="multiple" headerStyle={{ width: '3em' }}></Column>
                    <Column field="createdAtFormat" sortField="createdAt" header="Created At" sortable style={{ minWidth: '10rem' }}></Column>
                    <Column field="id" header="Worker ID" style={{ minWidth: '25rem' }}></Column>
                    <Column field="birthday" header="Birthday" style={{ minWidth: '10rem' }}></Column>
                    <Column field="id_type" header="Id Type" hidden={true} ></Column>
                    <Column field="id_number" header="Id Number" hidden={true} ></Column>
                    <Column field="platforms_connected" header="Platforms" hidden={true} ></Column>
                    {/* <Column field="id" header="Id" sortable style={{ minWidth: '12rem' }}></Column> */}
                    <Column body={(item) => {
                        // return <div>{item.platforms ? item.platforms.map(p =><div>{p.name}</div>)}</div>
                        return <div>{item.platforms ? item.platforms.map(p => <div>{p.platform}</div>) : ''}</div>
                    }} header="Platforms" hidden={true} style={{ minWidth: '12rem' }}></Column>
                    <Column field="given_name" header="First Name" sortable style={{ minWidth: '12rem' }}></Column>
                    <Column field="family_name" header="Last Name" sortable style={{ minWidth: '12rem' }}></Column>
                    <Column field="country" header="Country" hidden={true} ></Column>
                    <Column field="gender" header="Gender" hidden={true} ></Column>
                    <Column field="civil_status" header="Civil Status" hidden={true} s></Column>
                    <Column field="email" header="Email" sortable style={{ minWidth: '12rem' }}></Column>
                    <Column field="phone_number" header="Phone Number" style={{ minWidth: '12rem' }}></Column>
                    <Column field="address" header="Address" hidden={true} ></Column>
                    <Column field="is_subscribed" header="Subscribed" hidden={true} style={{ minWidth: '8rem' }} ></Column>
                    <Column field="last_payment_date" header="Last Payment" hidden={true} style={{ minWidth: '10rem' }}></Column>
                    <Column field="next_payment_date" header="Next Payment" hidden={true} style={{ minWidth: '10rem' }}></Column>
                    <Column field="id_type_b1" header="Id Type B1" hidden={true} ></Column>
                    <Column field="birthday_b1" header="Birthday B1" hidden={true}></Column>
                    <Column field="birth_country_b1" header="Country B1" hidden={true} ></Column>
                    <Column field="id_number_b1" header="Id Number B1" hidden={true} ></Column>
                    <Column field="full_name_b1" header="Full Name B1" hidden={true} ></Column>
                    <Column field="phone_number_b1" header="Phone Number B1" hidden={true} ></Column>
                    <Column field="percentage_b1" header="Percentage B1" hidden={true} ></Column>
                    <Column field="id_type_b2" header="Id Type B2" hidden={true}></Column>
                    <Column field="birthday_b2" header="Birthday B2" hidden={true}></Column>
                    <Column field="birth_country_b2" header="Country B2" hidden={true}></Column>
                    <Column field="id_number_b2" header="Id Number B2" hidden={true}></Column>
                    <Column field="full_name_b2" header="Full Name B2" hidden={true}></Column>
                    <Column field="phone_number_b2" header="Phone Number B2" hidden={true}></Column>
                    <Column field="percentage_b2" header="Percentage B2" hidden={true}></Column>
                    <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '8rem' }}></Column>
                </DataTable>
            </div>
            <div className="card">
                <DataTable value={unconfirmed}
                    dataKey="Username" paginator rows={10}
                    paginatorTemplate="PrevPageLink PageLinks NextPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} users"
                    header="Unconfirmed users" responsiveLayout="scroll">
                    <Column field="UserCreateDate" header="Created At" sortable style={{ minWidth: '10rem' }}></Column>
                    <Column field="Name" header="First Name" sortable style={{ minWidth: '12rem' }}></Column>
                    <Column field="Last" header="Last Name" sortable style={{ minWidth: '12rem' }}></Column>
                    <Column field="Email" header="Email" sortable style={{ minWidth: '12rem' }}></Column>
                    <Column field="Phone" header="Phone" sortable style={{ minWidth: '8rem' }} ></Column>
                </DataTable>
            </div>
            <div className="card">
                <DataTable value={issue}
                    dataKey="Username" paginator rows={10}
                    paginatorTemplate="PrevPageLink PageLinks NextPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} users"
                    header="Users with issues" responsiveLayout="scroll">
                    <Column field="UserCreateDate" header="Created At" sortable style={{ minWidth: '10rem' }}></Column>
                    <Column field="Username" header="Username" sortable style={{ minWidth: '12rem' }}></Column>
                    <Column field="Name" header="First Name" sortable style={{ minWidth: '12rem' }}></Column>
                    <Column field="Last" header="Last Name" sortable style={{ minWidth: '12rem' }}></Column>
                    <Column field="Email" header="Email" sortable style={{ minWidth: '12rem' }}></Column>
                    <Column field="Phone" header="Phone" sortable style={{ minWidth: '8rem' }} ></Column>
                    <Column body={issueBodyTemplate} exportable={false} style={{ minWidth: '8rem' }}></Column>
                </DataTable>
            </div>

            <Dialog visible={editDialog} style={{ width: '450px' }} header="Edit User" modal className="p-fluid" footer={productDialogFooter} onHide={hideDialog}>
                {//className={classNames({ 'p-invalid': submitted && !product.name })}
                    //submitted && !product.name && <small className="p-error">First names are required.</small>
                }
                <h3>User</h3>
                <div className="card">
                    <label htmlFor="is_subscribed">Is Subscribed</label>
                    <InputSwitch checked={user.is_subscribed} id="is_subscribed" onChange={(e) => onChangeUser(e, 'is_subscribed')} />
                    { //onChange={(e) => onChangeUser(e, 'is_subscribed')}
                    }
                </div>

                <div className="formgrid grid">
                    <div className="field col">
                        <label htmlFor="last_payment_date">Last Payment</label>
                        <Calendar id="last_payment_date" placeholder={dateFormat(user.last_payment_date)} dateFormat={"dd/mm/yy"} onChange={(e) => onChangeUser(e, 'last_payment_date')} showIcon monthNavigator yearNavigator yearRange="2022:2030" />
                    </div>
                    <div className="field col">
                        <label htmlFor="next_payment_date">Next Payment</label>
                        <Calendar id="next_payment_date" placeholder={dateFormat(user.next_payment_date)} dateFormat={"dd/mm/yy"} onChange={(e) => onChangeUser(e, 'next_payment_date')} showIcon monthNavigator yearNavigator yearRange="2022:2030" />
                    </div>
                </div>
                <div className="formgrid grid">
                    <div className="field col">
                        <label htmlFor="birthday">Birthday</label>
                        <Calendar id="birthday" placeholder={dateFormat(user.birthday)} dateFormat={"dd/mm/yy"} onChange={(e) => onChangeDateUser(e, 'birthday')} showIcon monthNavigator yearNavigator yearRange="1950:2022" />
                    </div>
                    <div className="field col">
                        <label htmlFor="promoter_id">Promoter ID</label>
                        <InputText id="promoter_id" value={user.promoter_id} onChange={(e) => {
                            setUser({ ...user, promoter_id: e.target.value });
                        }} />
                    </div>
                </div>

                <h3>Asegurado</h3>
                <div className="formgrid grid">
                    <div className="field col">
                        <label htmlFor="first_names">First Names</label>
                        <InputText id="first_names" value={worker_kyc1.first_names} onChange={(e) => onChangeKYC1(e, 'first_names')} />
                    </div>
                    <div className="field col">
                        <label htmlFor="last_names">Last Names</label>
                        <InputText id="last_names" value={worker_kyc1.last_names} onChange={(e) => onChangeKYC1(e, 'last_names')} />
                    </div>
                </div>
                <div className="formgrid grid">
                    <div className="field col">
                        <label htmlFor="id_type">Id Type</label>
                        <Dropdown options={[
                            {
                                label: 'Nacional',
                                value: 'nacional'
                            }, {
                                label: 'Pasaporte',
                                value: 'pasaporte'
                            }, {
                                label: 'Residencia',
                                value: 'residencia'
                            }
                        ]} id="id_type" value={worker_kyc1.id_type} onChange={(e) => onChangeKYC1(e, 'id_type')} />
                    </div>
                    <div className="field col">
                        <label htmlFor="id_number">Id Number</label>
                        <InputText id="id_number" value={worker_kyc1.id_number} onChange={(e) => onChangeKYC1(e, 'id_number')} />
                    </div>
                </div>
                <div className="formgrid grid">
                    <div className="field col">
                        <label htmlFor="gender">Gender</label>
                        <InputText id="gender" value={worker_kyc1.gender} onChange={(e) => onChangeKYC1(e, 'gender')} />
                    </div>
                    <div className="field col">
                        <label htmlFor="civil_status">Civil Status</label>
                        <InputText id="civil_status" value={worker_kyc1.civil_status} onChange={(e) => onChangeKYC1(e, 'civil_status')} />
                    </div>
                </div>
                <div className="formgrid grid">
                    <div className="field col">
                        <label htmlFor="address">Address</label>
                        <InputText id="address" value={worker_kyc1.address} onChange={(e) => onChangeKYC1(e, 'address')} />
                    </div>
                </div>

                <h3>Beneficiario #1</h3>
                <div className="formgrid grid">
                    <div className="field col">
                        <label htmlFor="first_names">First Names</label>
                        <InputText id="first_names" value={worker_kyc2.first_names} onChange={(e) => onChangeKYC2(e, 'first_names')} />
                    </div>
                    <div className="field col">
                        <label htmlFor="last_names">Last Names</label>
                        <InputText id="last_names" value={worker_kyc2.last_names} onChange={(e) => onChangeKYC2(e, 'last_names')} />
                    </div>
                </div>
                <div className="formgrid grid">
                    <div className="field col">
                        <label htmlFor="id_type">Id Type</label>
                        <Dropdown id="id_type" options={[
                            {
                                label: 'Nacional',
                                value: 'nacional'
                            }, {
                                label: 'Pasaporte',
                                value: 'pasaporte'
                            }, {
                                label: 'Residencia',
                                value: 'residencia'
                            }
                        ]} value={worker_kyc2.id_type} onChange={(e) => onChangeKYC2(e, 'id_type')} />
                    </div>
                    <div className="field col">
                        <label htmlFor="id_number">Id Number</label>
                        <InputText id="id_number" value={worker_kyc2.id_number} onChange={(e) => onChangeKYC2(e, 'id_number')} />
                    </div>
                </div>
                <div className="formgrid grid">
                    <div className="field col">
                        <label htmlFor="phone_number">Phone Number</label>
                        <InputText id="phone_number" value={worker_kyc2.phone_number} onChange={(e) => onChangeKYC2(e, 'phone_number')} />
                    </div>
                    <div className="field col">
                        <label htmlFor="birth_country">Country</label>
                        <InputText id="birth_country" value={worker_kyc2.birth_country} onChange={(e) => onChangeKYC2(e, 'birth_country')} />
                    </div>
                </div>
                <div className="formgrid grid">
                    <div className="field col">
                        <label htmlFor="birthday">Birthday</label>
                        <Calendar id="birthday" placeholder={dateFormat(worker_kyc2.birthday)} dateFormat={"dd/mm/yy"} onChange={(e) => onChangeDateKYC2(e, 'birthday')} showIcon monthNavigator yearNavigator yearRange="1950:2022" />
                    </div>
                    <div className="field col">
                        <label htmlFor="percentage">Percentage</label>
                        <InputNumber showButtons id="percentage" min={0} max={100} step={1} value={worker_kyc2.percentage} onChange={(e) => onChangeKYC2(e, 'percentage')} />
                    </div>
                </div>



                <h3>Beneficiario #2</h3>
                <div className="formgrid grid">
                    <div className="field col">
                        <label htmlFor="first_names">First Names</label>
                        <InputText id="first_names" value={worker_kyc3.first_names} onChange={(e) => onChangeKYC3(e, 'first_names')} />
                    </div>
                    <div className="field col">
                        <label htmlFor="last_names">Last Names</label>
                        <InputText id="last_names" value={worker_kyc3.last_names} onChange={(e) => onChangeKYC3(e, 'last_names')} />
                    </div>
                </div>
                <div className="formgrid grid">
                    <div className="field col">
                        <label htmlFor="id_type">Id Type</label>
                        <Dropdown options={[
                            {
                                label: 'Nacional',
                                value: 'nacional'
                            }, {
                                label: 'Pasaporte',
                                value: 'pasaporte'
                            }, {
                                label: 'Residencia',
                                value: 'residencia'
                            }
                        ]} id="id_type" value={worker_kyc3.id_type} onChange={(e) => onChangeKYC3(e, 'id_type')} />
                    </div>
                    <div className="field col">
                        <label htmlFor="id_number">Id Number</label>
                        <InputText id="id_number" value={worker_kyc3.id_number} onChange={(e) => onChangeKYC3(e, 'id_number')} />
                    </div>
                </div>
                <div className="formgrid grid">
                    <div className="field col">
                        <label htmlFor="phone_number">Phone Number</label>
                        <InputText id="phone_number" value={worker_kyc3.phone_number} onChange={(e) => onChangeKYC3(e, 'phone_number')} />
                    </div>
                    <div className="field col">
                        <label htmlFor="birth_country">Country</label>
                        <InputText id="birth_country" value={worker_kyc3.birth_country} onChange={(e) => onChangeKYC3(e, 'birth_country')} />
                    </div>

                </div>
                <div className="formgrid grid">
                    <div className="field col">
                        <label htmlFor="birthday">Birthday</label>
                        <Calendar id="birthday" placeholder={dateFormat(worker_kyc3.birthday)} dateFormat={"dd/mm/yy"} onChange={(e) => onChangeDateKYC3(e, 'birthday')} showIcon monthNavigator yearNavigator yearRange="1950:2022" />
                    </div>
                    <div className="field col">
                        <label htmlFor="percentage">Percentage</label>
                        <InputNumber showButtons min={0} max={100} step={1} id="percentage" value={worker_kyc3.percentage} onChange={(e) => onChangeKYC3(e, 'percentage')} />
                    </div>
                </div>

            </Dialog>
            <Dialog visible={pushDialog} style={{ width: '450px' }} modal className="p-fluid" onHide={hidePushDialog}>
                <div>
                    <div className="field">
                        <label>Title</label>
                        <InputText value={pushTitle} onChange={(e) => setPushTitle(e.target.value)} />
                    </div>
                    <div className="field">
                        <label>Subtitle</label>
                        <InputText value={pushSubtitle} onChange={(e) => setPushSubtitle(e.target.value)} />
                    </div>
                    <div className="field">
                        <label>Message</label>
                        <InputText value={pushMessage} onChange={(e) => setPushMessage(e.target.value)} />
                    </div>
                    <div className="field">
                        <label>Type</label>
                        <Dropdown value={pushType} options={typeOptions} onChange={(e) => setPushType(e.target.value)} />
                    </div>
                    <div className="field">
                        <label>Route</label>
                        <Dropdown value={pushRoute} options={routeOptions} onChange={(e) => setPushRoute(e.target.value)} />
                    </div>
                    <div className="field">
                        <label>Subroute</label>
                        <InputText value={pushSubroute} onChange={(e) => setPushSubroute(e.target.value)} />
                    </div>
                    <Button label="Send" onClick={() => {
                        sendPushNotificationToUsers()
                    }} />
                </div>
            </Dialog>
        </div>
    );
}
export default Users;
