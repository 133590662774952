/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getPayment = /* GraphQL */ `
  query GetPayment($id: ID!) {
    getPayment(id: $id) {
      id
      amount
      currency
      date
      worker_id
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listPayments = /* GraphQL */ `
  query ListPayments(
    $filter: ModelPaymentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPayments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        amount
        currency
        date
        worker_id
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncPayments = /* GraphQL */ `
  query SyncPayments(
    $filter: ModelPaymentFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPayments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        amount
        currency
        date
        worker_id
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getOrganization = /* GraphQL */ `
  query GetOrganization($id: ID!) {
    getOrganization(id: $id) {
      id
      name
      description
      logo
      owner_id
      email
      website
      phone_number
      address
      city
      state
      zip
      locations {
        name
        images
        description
        address
        city
        state
        zip
        latitude
        longitude
        phone_number
        website
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      clients {
        nextToken
        startedAt
      }
      products {
        nextToken
        startedAt
      }
      orders {
        nextToken
        startedAt
      }
    }
  }
`;
export const listOrganizations = /* GraphQL */ `
  query ListOrganizations(
    $filter: ModelOrganizationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrganizations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        logo
        owner_id
        email
        website
        phone_number
        address
        city
        state
        zip
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncOrganizations = /* GraphQL */ `
  query SyncOrganizations(
    $filter: ModelOrganizationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncOrganizations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        description
        logo
        owner_id
        email
        website
        phone_number
        address
        city
        state
        zip
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getClient = /* GraphQL */ `
  query GetClient($id: ID!) {
    getClient(id: $id) {
      id
      worker_id
      organization_id
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      leases {
        nextToken
        startedAt
      }
    }
  }
`;
export const listClients = /* GraphQL */ `
  query ListClients(
    $filter: ModelClientFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClients(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        worker_id
        organization_id
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const clientsByWorker = /* GraphQL */ `
  query ClientsByWorker(
    $worker_id: ID
    $sortDirection: ModelSortDirection
    $filter: ModelClientFilterInput
    $limit: Int
    $nextToken: String
  ) {
    clientsByWorker(
      worker_id: $worker_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        worker_id
        organization_id
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const clientsByOrganization = /* GraphQL */ `
  query ClientsByOrganization(
    $organization_id: ID
    $sortDirection: ModelSortDirection
    $filter: ModelClientFilterInput
    $limit: Int
    $nextToken: String
  ) {
    clientsByOrganization(
      organization_id: $organization_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        worker_id
        organization_id
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncClients = /* GraphQL */ `
  query SyncClients(
    $filter: ModelClientFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncClients(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        worker_id
        organization_id
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getLease = /* GraphQL */ `
  query GetLease($id: ID!) {
    getLease(id: $id) {
      id
      client_id
      product_id
      start_date
      end_date
      status
      amount
      currency
      down_payment
      interest
      term
      tax
      total
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      invoices {
        nextToken
        startedAt
      }
    }
  }
`;
export const listLeases = /* GraphQL */ `
  query ListLeases(
    $filter: ModelLeaseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLeases(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        client_id
        product_id
        start_date
        end_date
        status
        amount
        currency
        down_payment
        interest
        term
        tax
        total
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncLeases = /* GraphQL */ `
  query SyncLeases(
    $filter: ModelLeaseFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncLeases(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        client_id
        product_id
        start_date
        end_date
        status
        amount
        currency
        down_payment
        interest
        term
        tax
        total
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getInvoice = /* GraphQL */ `
  query GetInvoice($id: ID!) {
    getInvoice(id: $id) {
      id
      lease_id
      invoice_number
      amount
      payed_amount
      currency
      payment_method
      date
      status
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listInvoices = /* GraphQL */ `
  query ListInvoices(
    $filter: ModelInvoiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInvoices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        lease_id
        invoice_number
        amount
        payed_amount
        currency
        payment_method
        date
        status
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncInvoices = /* GraphQL */ `
  query SyncInvoices(
    $filter: ModelInvoiceFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncInvoices(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        lease_id
        invoice_number
        amount
        payed_amount
        currency
        payment_method
        date
        status
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getProduct = /* GraphQL */ `
  query GetProduct($id: ID!) {
    getProduct(id: $id) {
      id
      title
      subtitle
      discount_percent
      price
      original_price
      description
      category
      image
      locations {
        name
        images
        description
        address
        city
        state
        zip
        latitude
        longitude
        phone_number
        website
      }
      blur
      currency
      type
      manufacturer
      model
      year
      contract
      installment
      down_payment
      mileage
      value
      interest
      technology
      term_length
      term
      min_term
      max_term
      tax
      organization_id
      createdAt
      pickup_address
      pickup_lat
      pickup_long
      dropoff_address
      dropoff_lat
      dropoff_long
      miles_per_gallon
      transmission
      fuel_type
      door_count
      seat_count
      engine
      is_insured
      min_monthly_earnings
      min_rating
      min_trips_count
      notes
      published
      _version
      _deleted
      _lastChangedAt
      updatedAt
    }
  }
`;
export const listProducts = /* GraphQL */ `
  query ListProducts(
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        subtitle
        discount_percent
        price
        original_price
        description
        category
        image
        blur
        currency
        type
        manufacturer
        model
        year
        contract
        installment
        down_payment
        mileage
        value
        interest
        technology
        term_length
        term
        min_term
        max_term
        tax
        organization_id
        createdAt
        pickup_address
        pickup_lat
        pickup_long
        dropoff_address
        dropoff_lat
        dropoff_long
        miles_per_gallon
        transmission
        fuel_type
        door_count
        seat_count
        engine
        is_insured
        min_monthly_earnings
        min_rating
        min_trips_count
        notes
        published
        _version
        _deleted
        _lastChangedAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const productsByOrganization = /* GraphQL */ `
  query ProductsByOrganization(
    $organization_id: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    productsByOrganization(
      organization_id: $organization_id
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        subtitle
        discount_percent
        price
        original_price
        description
        category
        image
        blur
        currency
        type
        manufacturer
        model
        year
        contract
        installment
        down_payment
        mileage
        value
        interest
        technology
        term_length
        term
        min_term
        max_term
        tax
        organization_id
        createdAt
        pickup_address
        pickup_lat
        pickup_long
        dropoff_address
        dropoff_lat
        dropoff_long
        miles_per_gallon
        transmission
        fuel_type
        door_count
        seat_count
        engine
        is_insured
        min_monthly_earnings
        min_rating
        min_trips_count
        notes
        published
        _version
        _deleted
        _lastChangedAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncProducts = /* GraphQL */ `
  query SyncProducts(
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncProducts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        title
        subtitle
        discount_percent
        price
        original_price
        description
        category
        image
        blur
        currency
        type
        manufacturer
        model
        year
        contract
        installment
        down_payment
        mileage
        value
        interest
        technology
        term_length
        term
        min_term
        max_term
        tax
        organization_id
        createdAt
        pickup_address
        pickup_lat
        pickup_long
        dropoff_address
        dropoff_lat
        dropoff_long
        miles_per_gallon
        transmission
        fuel_type
        door_count
        seat_count
        engine
        is_insured
        min_monthly_earnings
        min_rating
        min_trips_count
        notes
        published
        _version
        _deleted
        _lastChangedAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getOrder = /* GraphQL */ `
  query GetOrder($id: ID!) {
    getOrder(id: $id) {
      id
      type
      product_id
      worker_id
      status
      approved
      assignee
      organization_id
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
    }
  }
`;
export const listOrders = /* GraphQL */ `
  query ListOrders(
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        product_id
        worker_id
        status
        approved
        assignee
        organization_id
        createdAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const ordersByOrganization = /* GraphQL */ `
  query OrdersByOrganization(
    $organization_id: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ordersByOrganization(
      organization_id: $organization_id
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        product_id
        worker_id
        status
        approved
        assignee
        organization_id
        createdAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncOrders = /* GraphQL */ `
  query SyncOrders(
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncOrders(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        type
        product_id
        worker_id
        status
        approved
        assignee
        organization_id
        createdAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getEvent = /* GraphQL */ `
  query GetEvent($id: ID!) {
    getEvent(id: $id) {
      id
      platform
      event_id
      type
      start_date
      timezone
      duration
      distance
      distance_unit
      pickup_address
      dropoff_address
      amount
      currency
      worker_id
      platform_profile_id
      other_info
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listEvents = /* GraphQL */ `
  query ListEvents(
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        platform
        event_id
        type
        start_date
        timezone
        duration
        distance
        distance_unit
        pickup_address
        dropoff_address
        amount
        currency
        worker_id
        platform_profile_id
        other_info
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const eventsByPlatformProfile = /* GraphQL */ `
  query EventsByPlatformProfile(
    $platform_profile_id: ID
    $sortDirection: ModelSortDirection
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventsByPlatformProfile(
      platform_profile_id: $platform_profile_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        platform
        event_id
        type
        start_date
        timezone
        duration
        distance
        distance_unit
        pickup_address
        dropoff_address
        amount
        currency
        worker_id
        platform_profile_id
        other_info
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncEvents = /* GraphQL */ `
  query SyncEvents(
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncEvents(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        platform
        event_id
        type
        start_date
        timezone
        duration
        distance
        distance_unit
        pickup_address
        dropoff_address
        amount
        currency
        worker_id
        platform_profile_id
        other_info
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getConnectionAttempt = /* GraphQL */ `
  query GetConnectionAttempt($id: ID!) {
    getConnectionAttempt(id: $id) {
      id
      type
      description
      platform
      status
      worker_id
      data
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listConnectionAttempts = /* GraphQL */ `
  query ListConnectionAttempts(
    $filter: ModelConnectionAttemptFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listConnectionAttempts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        description
        platform
        status
        worker_id
        data
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncConnectionAttempts = /* GraphQL */ `
  query SyncConnectionAttempts(
    $filter: ModelConnectionAttemptFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncConnectionAttempts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        type
        description
        platform
        status
        worker_id
        data
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getEarning = /* GraphQL */ `
  query GetEarning($id: ID!) {
    getEarning(id: $id) {
      id
      datestr
      earnings
      currency
      count_trips
      worker_id
      online_hours
      ontrip_hours
      driver_canceled_trips
      acceptance_rate
      loyalty_points
      tips
      net_fare
      cash_received
      promotions
      taxes
      online
      trips
      points
      platform_profile_id
      other_info
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listEarnings = /* GraphQL */ `
  query ListEarnings(
    $filter: ModelEarningFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEarnings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        datestr
        earnings
        currency
        count_trips
        worker_id
        online_hours
        ontrip_hours
        driver_canceled_trips
        acceptance_rate
        loyalty_points
        tips
        net_fare
        cash_received
        promotions
        taxes
        online
        trips
        points
        platform_profile_id
        other_info
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const byWorkerByDate = /* GraphQL */ `
  query ByWorkerByDate(
    $worker_id: ID
    $datestr: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEarningFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byWorkerByDate(
      worker_id: $worker_id
      datestr: $datestr
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        datestr
        earnings
        currency
        count_trips
        worker_id
        online_hours
        ontrip_hours
        driver_canceled_trips
        acceptance_rate
        loyalty_points
        tips
        net_fare
        cash_received
        promotions
        taxes
        online
        trips
        points
        platform_profile_id
        other_info
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const byPlatformProfileByDate = /* GraphQL */ `
  query ByPlatformProfileByDate(
    $platform_profile_id: ID
    $datestr: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelEarningFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byPlatformProfileByDate(
      platform_profile_id: $platform_profile_id
      datestr: $datestr
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        datestr
        earnings
        currency
        count_trips
        worker_id
        online_hours
        ontrip_hours
        driver_canceled_trips
        acceptance_rate
        loyalty_points
        tips
        net_fare
        cash_received
        promotions
        taxes
        online
        trips
        points
        platform_profile_id
        other_info
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncEarnings = /* GraphQL */ `
  query SyncEarnings(
    $filter: ModelEarningFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncEarnings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        datestr
        earnings
        currency
        count_trips
        worker_id
        online_hours
        ontrip_hours
        driver_canceled_trips
        acceptance_rate
        loyalty_points
        tips
        net_fare
        cash_received
        promotions
        taxes
        online
        trips
        points
        platform_profile_id
        other_info
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getPlatformProfile = /* GraphQL */ `
  query GetPlatformProfile($id: ID!) {
    getPlatformProfile(id: $id) {
      id
      worker_id
      account_id
      platform
      country
      first_name
      last_name
      email
      phone_prefix
      phone_number
      birthday
      city_name
      url_picture
      id_type
      id_number
      rating
      lifetime_trips
      time_since_ft
      level_name
      level_number
      acceptance_rate
      vehicle_info {
        type
        brand
        model
        year
        license_plate
        license_plate_state
        state
        vin
      }
      gender
      cancelation_rate
      thumbs_up
      bank_info
      palenca_worker_id
      last_fetched_data
      last_fetched_events
      can_fetch_data
      activation_status
      employment_info
      other_info
      metrics_info
      status
      deleted
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      events {
        nextToken
        startedAt
      }
      earnings {
        nextToken
        startedAt
      }
    }
  }
`;
export const listPlatformProfiles = /* GraphQL */ `
  query ListPlatformProfiles(
    $filter: ModelPlatformProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPlatformProfiles(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        worker_id
        account_id
        platform
        country
        first_name
        last_name
        email
        phone_prefix
        phone_number
        birthday
        city_name
        url_picture
        id_type
        id_number
        rating
        lifetime_trips
        time_since_ft
        level_name
        level_number
        acceptance_rate
        gender
        cancelation_rate
        thumbs_up
        bank_info
        palenca_worker_id
        last_fetched_data
        last_fetched_events
        can_fetch_data
        activation_status
        employment_info
        other_info
        metrics_info
        status
        deleted
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncPlatformProfiles = /* GraphQL */ `
  query SyncPlatformProfiles(
    $filter: ModelPlatformProfileFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPlatformProfiles(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        worker_id
        account_id
        platform
        country
        first_name
        last_name
        email
        phone_prefix
        phone_number
        birthday
        city_name
        url_picture
        id_type
        id_number
        rating
        lifetime_trips
        time_since_ft
        level_name
        level_number
        acceptance_rate
        gender
        cancelation_rate
        thumbs_up
        bank_info
        palenca_worker_id
        last_fetched_data
        last_fetched_events
        can_fetch_data
        activation_status
        employment_info
        other_info
        metrics_info
        status
        deleted
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getWorker = /* GraphQL */ `
  query GetWorker($id: ID!) {
    getWorker(id: $id) {
      id
      image
      email
      given_name
      middle_name
      family_name
      second_family_name
      birthday
      type
      phone_number
      country
      country_id
      address
      identifier
      identifier_type
      worker_kyc {
        type
        first_names
        last_names
        id_type
        id_number
        birth_country
        birthday
        gender
        civil_status
        email
        phone_number
        address
        percentage
      }
      referral_id
      promoter_id
      is_subscribed
      last_payment_date
      next_payment_date
      devices {
        device_token
        device_platform
        send_push
      }
      credit_cards {
        token
        card_holder
        last_digits
        network
      }
      notifications {
        title
        subtitle
        message
        send_push
        sent_by
        type
        route
        sub_route
        read
        created_at
      }
      subscription {
        onvo_subscription_id
        type
        amount
        currency
        end_date
      }
      loymark_account_id
      loymark_account_number
      onboarding_platforms
      onvo_customer_id
      palenca_user_id
      organization_id
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      payments {
        nextToken
        startedAt
      }
      events {
        nextToken
        startedAt
      }
      earnings {
        nextToken
        startedAt
      }
      earningsByDate {
        nextToken
        startedAt
      }
      platform_profiles {
        nextToken
        startedAt
      }
    }
  }
`;
export const listWorkers = /* GraphQL */ `
  query ListWorkers(
    $filter: ModelWorkerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listWorkers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        image
        email
        given_name
        middle_name
        family_name
        second_family_name
        birthday
        type
        phone_number
        country
        country_id
        address
        identifier
        identifier_type
        referral_id
        promoter_id
        is_subscribed
        last_payment_date
        next_payment_date
        loymark_account_id
        loymark_account_number
        onboarding_platforms
        onvo_customer_id
        palenca_user_id
        organization_id
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncWorkers = /* GraphQL */ `
  query SyncWorkers(
    $filter: ModelWorkerFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncWorkers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        image
        email
        given_name
        middle_name
        family_name
        second_family_name
        birthday
        type
        phone_number
        country
        country_id
        address
        identifier
        identifier_type
        referral_id
        promoter_id
        is_subscribed
        last_payment_date
        next_payment_date
        loymark_account_id
        loymark_account_number
        onboarding_platforms
        onvo_customer_id
        palenca_user_id
        organization_id
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getCupon = /* GraphQL */ `
  query GetCupon($id: ID!) {
    getCupon(id: $id) {
      id
      title
      subtitle
      discount_percent
      price
      original_price
      description
      category
      image
      locations {
        name
        images
        description
        address
        city
        state
        zip
        latitude
        longitude
        phone_number
        website
      }
      blur
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listCupons = /* GraphQL */ `
  query ListCupons(
    $filter: ModelCuponFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCupons(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        subtitle
        discount_percent
        price
        original_price
        description
        category
        image
        blur
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncCupons = /* GraphQL */ `
  query SyncCupons(
    $filter: ModelCuponFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCupons(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        title
        subtitle
        discount_percent
        price
        original_price
        description
        category
        image
        blur
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getSpot = /* GraphQL */ `
  query GetSpot($id: ID!) {
    getSpot(id: $id) {
      id
      title
      description
      image
      hours {
        day
        start_time
        end_time
        description
      }
      location {
        name
        images
        description
        address
        city
        state
        zip
        latitude
        longitude
        phone_number
        website
      }
      address
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listSpots = /* GraphQL */ `
  query ListSpots(
    $filter: ModelSpotFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSpots(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        description
        image
        address
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncSpots = /* GraphQL */ `
  query SyncSpots(
    $filter: ModelSpotFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSpots(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        title
        description
        image
        address
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getAppSetting = /* GraphQL */ `
  query GetAppSetting($id: ID!) {
    getAppSetting(id: $id) {
      id
      loymark_token
      loymark_session_id
      platform_availability {
        platform
        online
      }
      module_availability {
        module
        online
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const listAppSettings = /* GraphQL */ `
  query ListAppSettings(
    $filter: ModelAppSettingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAppSettings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        loymark_token
        loymark_session_id
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncAppSettings = /* GraphQL */ `
  query SyncAppSettings(
    $filter: ModelAppSettingFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAppSettings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        loymark_token
        loymark_session_id
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getInAppRating = /* GraphQL */ `
  query GetInAppRating($id: ID!) {
    getInAppRating(id: $id) {
      id
      rating
      content
      worker_id
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
    }
  }
`;
export const listInAppRatings = /* GraphQL */ `
  query ListInAppRatings(
    $filter: ModelInAppRatingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInAppRatings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        rating
        content
        worker_id
        createdAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const reviewsByWorker = /* GraphQL */ `
  query ReviewsByWorker(
    $worker_id: ID
    $sortDirection: ModelSortDirection
    $filter: ModelInAppRatingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    reviewsByWorker(
      worker_id: $worker_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        rating
        content
        worker_id
        createdAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncInAppRatings = /* GraphQL */ `
  query SyncInAppRatings(
    $filter: ModelInAppRatingFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncInAppRatings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        rating
        content
        worker_id
        createdAt
        _version
        _deleted
        _lastChangedAt
        updatedAt
      }
      nextToken
      startedAt
    }
  }
`;
