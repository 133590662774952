import './App.css';
import React, { useEffect, useState } from 'react'
import { withAuthenticator } from '@aws-amplify/ui-react'
import Amplify, { API, graphqlOperation, Auth, Storage } from 'aws-amplify'

/* src/App.js */

import awsExports from "./aws-exports";
import { Admin } from './layouts/Admin';
import Cupons from './screens/Cupons';
import Users from './screens/Users';
import IndividualEarnings from './screens/IndividualEarnings';
import Platforms  from './screens/Platforms';
import Locations from './screens/Locations';
import Products from './screens/Products';
import Modules from './screens/Modules';
import Orders from './screens/Orders';


import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import ConnectionAttempts from './screens/ConnectionAttempts';
import Home from './screens/Home';
import { DBDump } from './screens/DBDump';
import PalencaLink from './screens/PalencaLink';


Amplify.configure(awsExports);


function App() {

  const [user, setUser] = useState(null);


  useEffect(() => {
    getUser();
  }, []);


  const downloadImage = (uri) => {
    return Storage.get(uri)
    .then((result) => {
      return result;
    })
    .catch((error) => {
      return error;
    });
  };

  const getUser = async () => {
    const currentUser = await Auth.currentAuthenticatedUser();
    const profilePicture=await downloadImage(currentUser.username+"_profile.jpeg");
    const _attributes={
      ...currentUser.attributes,
      image:profilePicture
    };
    const _user={
      ...currentUser,
      attributes:_attributes
    }
    console.log(_user)
    if (currentUser) {
      setUser(_user);
    }
  }


  return (
    <div className="App">
      <Router>
        <Admin user={user}>
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route path="/cupons">
              <Cupons />
            </Route>
            <Route path="/locations/:id">
              <Locations />
            </Route>                        
            <Route path="/users">
              <Users />
            </Route>
            <Route path="/individualEarnings/:id">
              <IndividualEarnings />
            </Route>            
            <Route path="/connections">
              <ConnectionAttempts />
            </Route>            
            <Route path="/platforms">
              <Platforms />
            </Route>            
            <Route path="/products">
              <Products />
            </Route>            
            <Route path="/orders">
              <Orders />
            </Route>                        
            <Route path="/modules">
              <Modules />
            </Route>                        
            <Route path="/db">
              <DBDump />
            </Route>                        
            <Route path="/palenca">
              <PalencaLink />
            </Route>                        
          </Switch>
        </Admin>
      </Router>
      
    </div>
  );
}

export default withAuthenticator(App);
