import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment'
import { API, graphqlOperation } from 'aws-amplify'
import { listPlatformProfiles, byPlatformProfileByDate } from '../graphql/queries';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { TabMenu } from 'primereact/tabmenu';
import {
    useParams
  } from "react-router-dom";
import { Dropdown } from 'primereact/dropdown';
import { linkPalencaPlatformProfile, refreshPlatformEarnings } from '../graphql/mutations';
import Platforms from './Platforms';

function IndividualEarnings(){
    
    const { id } = useParams();

    const [activeIndex, setActiveIndex] = useState(-1);
    const [activeIndexCurrentWeek, setActiveIndexCurrentWeek] = useState(-1);
    const [activeIndexWeekly, setActiveIndexWeekly] = useState(-1);
    const [activeIndexMonthly, setActiveIndexMonthly] = useState(-1);

    const [currentWeekData, setCurrentWeekData] = useState([]); 
    const [weeklyData, setWeeklyData]=useState([]);
    const [monthlyData, setMonthlyData]=useState([]);

    const [earnings, setEarnings] = useState([]);
    const [platform, setPlatform] = useState('uber');
    const [profiles, setProfiles] = useState([]);
    const [labels, setLabels] = useState([{label:"",id:""}]);
    const [linking, setLinking] = useState(false);
    const [refreshing, setRefreshing] = useState(false);
    const toast = useRef(null);

    useEffect(() => {
//      fetchData();
      getEarnings();
    }, []); 


    const getEarnings = async () => {
      const getPlatformProfilesRes = await API.graphql(
        graphqlOperation(listPlatformProfiles, {
          filter: {
            worker_id: {
              eq: id
            }
          },
          limit: 1000
        })
      );
      if (
        getPlatformProfilesRes.data &&
        getPlatformProfilesRes.data.listPlatformProfiles
      ) {

        const oneWeekData = [];
        const oneMonthData = [];
        const threeMonthData = [];
        const earnings_=[];
        const profiles_=[];
        const labels_=[];
        for (const profile of getPlatformProfilesRes.data.listPlatformProfiles
          .items) {
            profiles_.push(profile);
            const getEarningsRes = await API.graphql(
              graphqlOperation(byPlatformProfileByDate, {
                platform_profile_id: profile.id,
                sortDirection: "DESC",
              })
              );
          if (
            getEarningsRes.data &&
            getEarningsRes.data.byPlatformProfileByDate
          ) {
            // console.log(getEarningsRes.data.byPlatformProfileByDate.items);
            profile.earnings =
              getEarningsRes.data.byPlatformProfileByDate.items.reverse().map((i) => ({
                value: i.earnings,
                timestamp: i.datestr,
                dateFormat: dateFormat(i.datestr),
                trips: i.count_trips,
                hours: i.online_hours,
                promotions: i.promotions
              }));

            // setEarnings(getEarningsRes.data.byWorkerByDate.items.map(i => ({ value: i })))
          } else {
            // setApiError(getEarningsRes.data.listEarnings.message);
          }
          const profileEarn = []
          let day = moment().subtract(4, 'month'); 
          while (day < moment().day(7)) {
            const ern = profile.earnings.find(e => e.timestamp === day.format('YYYY-MM-DD'));
            if (!ern) {
              profileEarn.push({ value: 0, dateFormat: dateFormat(day), timestamp: day.format('YYYY-MM-DD'), trips:0, hours:0, promotions:0 })
            } else {
              profileEarn.push(ern);
            }
            day = day.add(1, 'day');
          }

          const oneWeek = profileEarn.filter(e => moment(e.timestamp) >= moment().day(0));
          oneWeekData.push(oneWeek); 

          const weekTwo = profileEarn.filter(e => moment(e.timestamp) >= moment().day(0).subtract(1, 'week') && moment(e.timestamp) < moment().day(0));
          const weekThree = profileEarn.filter(e => moment(e.timestamp) >= moment().day(0).subtract(2, 'week') && moment(e.timestamp) < moment().day(0).subtract(1, 'week'));
          const weekFour = profileEarn.filter(e => moment(e.timestamp) >= moment().day(0).subtract(3, 'week') && moment(e.timestamp) < moment().day(0).subtract(2, 'week'));

          const oneMonth = profileEarn.filter(e => moment(e.timestamp) >= moment().startOf('month') && moment(e.timestamp) < moment());
          const twoMonth = profileEarn.filter(e => moment(e.timestamp) >= moment().startOf('month').subtract(1, 'month') && moment(e.timestamp) < moment().startOf('month'));
          const threeMonth = profileEarn.filter(e => moment(e.timestamp) >= moment().startOf('month').subtract(2, 'month') && moment(e.timestamp) < moment().startOf('month').subtract(1, 'month'));


          oneMonthData.push([
              {
                value: weekFour.reduce((a, b) => a + b.value, 0),
                firstDayOfWeek: weekFour[0].dateFormat,
                lastDayOfWeek: weekFour[weekFour.length -1].dateFormat,
                trips: weekFour.reduce((a, b) => a + b.trips, 0),
                hours: weekFour.reduce((a, b) => a + b.hours, 0),
                promotions: weekFour.reduce((a, b) => a + b.promotions, 0),                  
              },
              {
                value: weekThree.reduce((a, b) => a + b.value, 0),
                firstDayOfWeek: weekThree[0].dateFormat,
                lastDayOfWeek: weekThree[weekThree.length -1].dateFormat,
                trips: weekThree.reduce((a, b) => a + b.trips, 0),
                hours: weekThree.reduce((a, b) => a + b.hours, 0),
                promotions: weekThree.reduce((a, b) => a + b.promotions, 0),                  
              },
              {
                value: weekTwo.reduce((a, b) => a + b.value, 0),
                firstDayOfWeek: weekTwo[0].dateFormat,
                lastDayOfWeek: weekTwo[weekTwo.length -1].dateFormat,
                trips: weekTwo.reduce((a, b) => a + b.trips, 0),
                hours: weekTwo.reduce((a, b) => a + b.hours, 0),
                promotions: weekTwo.reduce((a, b) => a + b.promotions, 0),                  
              },
              {
                value: oneWeek.reduce((a, b) => a + b.value, 0),                    
                firstDayOfWeek: oneWeek[0].dateFormat,
                lastDayOfWeek: oneWeek[oneWeek.length -1].dateFormat,
                trips: oneWeek.reduce((a, b) => a + b.trips, 0),
                hours: oneWeek.reduce((a, b) => a + b.hours, 0),
                promotions: oneWeek.reduce((a, b) => a + b.promotions, 0),
              }]
          );

          threeMonthData.push([
              {
                value: threeMonth.reduce((a, b) => a + b.value, 0),
                firstDayOfMonth: threeMonth[0].dateFormat,
                lastDayOfMonth: threeMonth[threeMonth.length -1].dateFormat,
                month: moment(threeMonth[0].timestamp).format('MMMM'),
                trips: threeMonth.reduce((a, b) => a + b.trips, 0),
                hours: threeMonth.reduce((a, b) => a + b.hours, 0),
                promotions: threeMonth.reduce((a, b) => a + b.promotions, 0),                                    
              },
              {
                value: twoMonth.reduce((a, b) => a + b.value, 0),
                firstDayOfMonth: twoMonth[0].dateFormat,
                lastDayOfMonth: twoMonth[twoMonth.length -1].dateFormat,
                month: moment(twoMonth[0].timestamp).format('MMMM'),
                trips: twoMonth.reduce((a, b) => a + b.trips, 0),
                hours: twoMonth.reduce((a, b) => a + b.hours, 0),
                promotions: twoMonth.reduce((a, b) => a + b.promotions, 0),                                                      
              },
              {
                value: oneMonth.reduce((a, b) => a + b.value, 0),
                firstDayOfMonth: oneMonth[0].dateFormat,
                lastDayOfMonth: oneMonth[oneMonth.length -1].dateFormat,
                month: moment(oneMonth[0].timestamp).format('MMMM'),
                trips: oneMonth.reduce((a, b) => a + b.trips, 0),
                hours: oneMonth.reduce((a, b) => a + b.hours, 0),
                promotions: oneMonth.reduce((a, b) => a + b.promotions, 0),                  
              }]
          );
          labels_.push({
            label: profile.platform,
            id: profile.id,
          });
          earnings_.push(profile.earnings);
      }
          //console.log(threeMonthData);
          const _allData={
              "1S": oneWeekData,
              "1M": oneMonthData,
              "3M": threeMonthData
          }
          
          setEarnings(earnings_);
          setProfiles(profiles_);

          setCurrentWeekData(oneWeekData);
          setWeeklyData(oneMonthData);
          setMonthlyData(threeMonthData);

          setLabels(labels_);
//          console.log("select data", oneWeekData[labels_[0].label]);
          console.log('Current Week Data', oneWeekData);
          console.log('Weekly Data', oneMonthData);
          console.log('Monthly Data', threeMonthData);                
          console.log('profiles',profiles_);
          console.log('earnings',earnings_);
          if(profiles_.length > 0) {
            setActiveIndex(0);
            setActiveIndexCurrentWeek(0);
            setActiveIndexWeekly(0);
            setActiveIndexMonthly(0);
          }
        }
    };

    const tabEarnings = () => {
      return (
          <React.Fragment>
              <TabMenu model={labels} activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)} />
          </React.Fragment>
      )
    } 

    const tabCurrentWeek = () => {
      return (
          <React.Fragment>
              <TabMenu model={labels} activeIndex={activeIndexCurrentWeek} onTabChange={(e) => setActiveIndexCurrentWeek(e.index)} />
          </React.Fragment>
      )
    } 

    const tabWeekly = () => {
      return (
          <React.Fragment>
              <TabMenu model={labels} activeIndex={activeIndexWeekly} onTabChange={(e) => setActiveIndexWeekly(e.index)} />
          </React.Fragment>
      )
    } 

    const tabMonthly = () => {
      return (
          <React.Fragment>
              <TabMenu model={labels} activeIndex={activeIndexMonthly} onTabChange={(e) => setActiveIndexMonthly(e.index)} />
          </React.Fragment>
      )
    } 

    const dateFormat=(date)=>{
      if (typeof date ==="undefined" || date ===null ) return date; 
      return moment(date).format('DD/MM/YYYY')
  }

  const refreshEarnings = async () => {
    try {
      const refreshRes = await API.graphql(graphqlOperation(refreshPlatformEarnings, { platform, user_id: id }));
      console.log('linkRes', refreshRes);
      window.location.reload();
    } catch (err) {
      console.log(err);
    }
  }

  const linkExistingAccount = async () => {
    try {
      const linkRes = await API.graphql(graphqlOperation(linkPalencaPlatformProfile, { platform, platform_id: id, user_id: id }));
      console.log('linkRes', linkRes);
      window.location.reload();
    } catch (err) {
      console.log(err);
    }
  }

  const options = [{label: 'Uber', value: 'uber'}, {label: 'Uber Eats', value: 'uber-eats'}, {label: 'inDriver', value: 'indriver'}, {label: 'rappi', value: 'rappi'}, {label: 'DiDi', value: 'didi'}, {label: 'DiDi Food', value: 'didi-food'}, {label: 'Cornershop', value: 'cornershop'}, { label: 'PedidosYa', value: 'pedidosya'}];


    return (
        <div className="datatable-crud-demo">
            <Toast ref={toast} />

            <div style={{ textAlign: 'left', marginBottom: 40 }}>
              <Dropdown style={{ marginRight: 40, minWidth: 200 }} options={options.filter(o => !profiles.map(p => p.platform).some(p => p === o.value))} value={platform} onChange={(e) => setPlatform(e.value)} placeholder="Platform" />
              <Button style={{ minWidth: 300 }} loading={linking} label="Link Existing Palenca account" icon="pi pi-link" onClick={async () => {
                setLinking(true);
                await linkExistingAccount();
                setLinking(false);
              } } />
            </div>

            <div style={{ textAlign: 'left', marginBottom: 40 }}>
              <Dropdown style={{ marginRight: 40, minWidth: 200 }} options={options.filter(o => profiles.map(p => p.platform).some(p => p === o.value))} value={platform} onChange={(e) => setPlatform(e.value)} placeholder="Platform" />
              <Button style={{ minWidth: 300 }} loading={refreshing} label="Refresh platform earnings" icon="pi pi-refresh" onClick={async () => {
                setRefreshing(true);
                await refreshEarnings();
                setRefreshing(false);
              } } />
            </div>

            <div className="card"> 
                <Toolbar  left={<h3>Profiles</h3>} ></Toolbar>
                {/* <DataTable paginator rows={30} rowsPerPageOptions={[ 30, 100]}
                paginatorTemplate="PrevPageLink PageLinks NextPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} earnings"
                header={tab}
                value={profiles[activeIndex]}
                //dataKey="id" 
                responsiveLayout="scroll">
                    <Column field="id" header="ID" style={{ minWidth: '8rem' }}></Column>                      
                    <Column field="name" header="Count trips" sortable style={{ minWidth: '8rem' }}></Column>                  
                    <Column field="value" header="Earnings" sortable style={{ minWidth: '8rem' }}></Column>
                </DataTable> */}
                {profiles.map(p => <div className='profile'>
                  <div>
                    <h4>{p.platform}</h4>
                    <img src={p.url_picture} />
                  </div>
                  <div>
                    <div className='label'>First Name</div>
                    <div className='value'>{p.first_name ? p.first_name : 'N/A'}</div>
                    <div className='label'>Last Name</div>
                    <div className='value'>{p.last_name ? p.last_name : 'N/A'}</div>
                    <div className='label'>ID Number</div>
                    <div className='value'>{p.id_number ? p.id_number : 'N/A'}</div>
                    <div className='label'>ID Type</div>
                    <div className='value'>{p.id_type ? p.id_type : 'N/A'}</div>
                    <div className='label'>Level</div>
                    <div className='value'>{p.level_name ? p.level_name : 'N/A'}</div>
                    <div className='label'>Phone</div>
                    <div className='value'>{p.phone_number ? p.phone_number : 'N/A'}</div>
                    <div className='label'>Lifetime Trips</div>
                    <div className='value'>{p.lifetime_trips ? p.lifetime_trips : 'N/A'}</div>
                    <div className='label'>Rating</div>
                    <div className='value'>{p.rating ? p.rating : 'N/A'}</div>
                    <div className='label'>Acceptance Rate</div>
                    <div className='value'>{p.acceptance_rate ? p.acceptance_rate : 'N/A'}</div>
                    <div className='label'>Activation Status</div>
                    <div className='value'>{p.activation_status ? p.activation_status : 'N/A'}</div>
                  </div>
                  <div>
                    <div className='label'>Bank Info</div>
                    <div className='value'>{p.bank_info ? p.bank_info : 'N/A'}</div>
                    <div className='label'>Birthday</div>
                    <div className='value'>{p.birthday ? p.birthday : 'N/A'}</div>

                    <div className='label'>Can Fetch Data</div>
                    <div className='value'>{p.can_fetch_data}</div>
                    <div className='label'>Last Fetched Data</div>
                    <div className='value'>{p.last_fetched_data}</div>
                    <div className='label'>Status</div>
                    <div className='value'>{p.status}</div>

                    <div className='label'>Cancelation Rate</div>
                    <div className='value'>{p.cancelation_rate ? p.cancelation_rate : 'N/A'}</div>

                    <div className='label'>City Name</div>
                    <div className='value'>{p.city_name ? p.city_name : 'N/A'}</div>

                    <div className='label'>Country</div>
                    <div className='value'>{p.country ? p.country : 'N/A'}</div>

                    <div className='label'>Email</div>
                    <div className='value'>{p.email ? p.email : 'N/A'}</div>

                    <div className='label'>Employment Info</div>
                    <div className='value'>{p.employment_info ? p.employment_info : 'N/A'}</div>

                    <div className='label'>Time in platform</div>
                    <div className='value'>{p.time_since_ft}</div>
                  </div>

                </div>)}
            </div> 

            <div className="card"> 
                <Toolbar  left={<h3>Current Week Earnings</h3>} ></Toolbar>
                <DataTable paginator rows={30} rowsPerPageOptions={[ 30, 100]}
                paginatorTemplate="PrevPageLink PageLinks NextPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} earnings"
                header={tabCurrentWeek}
                value={currentWeekData[activeIndexCurrentWeek]}
                //dataKey="id" 
                responsiveLayout="scroll">
                    <Column field="dateFormat" sortField="timestamp" header="Date" sortable style={{ minWidth: '8rem' }}></Column>                      
                    <Column field="value" header="Earnings" sortable style={{ minWidth: '8rem' }}></Column>
                    <Column field="trips" header="Count trips" sortable style={{ minWidth: '8rem' }}></Column>                  
                    <Column field="hours" header="Hours" sortable style={{ minWidth: '8rem' }}></Column>                  
                </DataTable>
            </div>          

  
            <div className="card"> 
                <Toolbar  left={<h3>Last 4 Weeks Earnings</h3>} ></Toolbar>
                <DataTable paginator rows={30} rowsPerPageOptions={[ 30, 100]}
                paginatorTemplate="PrevPageLink PageLinks NextPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} earnings"
                header={tabWeekly}
                value={weeklyData[activeIndexWeekly]}
                //dataKey="id" 
                responsiveLayout="scroll">
                    <Column field="firstDayOfWeek" header="First Day of Week" style={{ minWidth: '8rem' }}></Column>                      
                    <Column field="lastDayOfWeek" header="Last Day of Week" style={{ minWidth: '8rem' }}></Column>                                          
                    <Column field="value" header="Earnings" sortable style={{ minWidth: '8rem' }}></Column>
                    <Column field="trips" header="Count trips" sortable style={{ minWidth: '8rem' }}></Column>                  
                    <Column field="hours" header="Hours" sortable style={{ minWidth: '8rem' }}></Column>                  
                    <Column field="promotions" header="Promotions" sortable style={{ minWidth: '8rem' }}></Column>                                      
                </DataTable>
            </div>          

            <div className="card"> 
                <Toolbar  left={<h3>Last 3 Months Earnings</h3>} ></Toolbar>
                <DataTable paginator rows={30} rowsPerPageOptions={[ 30, 100]}
                paginatorTemplate="PrevPageLink PageLinks NextPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} earnings"
                header={tabMonthly}
                value={monthlyData[activeIndexMonthly]}
                //dataKey="id" 
                responsiveLayout="scroll">
                    <Column field="month" header="Month" sortable style={{ minWidth: '8rem' }}></Column>                      
                    <Column field="firstDayOfMonth" header="First Day of Month" sortable style={{ minWidth: '8rem' }}></Column>                      
                    <Column field="lastDayOfMonth" header="Last Day of Month" sortable style={{ minWidth: '8rem' }}></Column>                                          
                    <Column field="value" header="Earnings" sortable style={{ minWidth: '8rem' }}></Column>
                    <Column field="trips" header="Count trips" sortable style={{ minWidth: '8rem' }}></Column>                  
                    <Column field="hours" header="Hours" sortable style={{ minWidth: '8rem' }}></Column>                  
                    <Column field="promotions" header="Promotions" sortable style={{ minWidth: '8rem' }}></Column>                                      
                </DataTable>
            </div>          
            

            <div className="card"> 
                <Toolbar  left={<h3>All Earnings</h3>} ></Toolbar>
                <DataTable paginator rows={30} rowsPerPageOptions={[ 30, 100]}
                paginatorTemplate="PrevPageLink PageLinks NextPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} earnings"
                header={tabEarnings}
                value={earnings[activeIndex]}
                //dataKey="id" 
                responsiveLayout="scroll">
                    <Column field="dateFormat" sortField="timestamp" header="Date" sortable style={{ minWidth: '8rem' }}></Column>                      
                    <Column field="trips" header="Count trips" sortable style={{ minWidth: '8rem' }}></Column>                  
                    <Column field="value" header="Earnings" sortable style={{ minWidth: '8rem' }}></Column>
                </DataTable>
            </div>                        
        </div>
    );
}
export default IndividualEarnings;