import React, { useEffect, useState, useRef } from 'react'
import { API, graphqlOperation, label } from 'aws-amplify'
import { createCupon, deleteCupon, updateCupon, createProduct, deleteProduct, updateProduct } from '../graphql/mutations';
import { listCupons, getCupon, listProducts, getProduct, getOrganization, listOrganizations } from '../graphql/queries';
import { onCreateCupon } from '../graphql/subscriptions';
import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { Toolbar } from 'primereact/toolbar';
import { InputTextarea } from 'primereact/inputtextarea';
import { RadioButton } from 'primereact/radiobutton';
import { InputNumber } from 'primereact/inputnumber';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Auth, Storage } from 'aws-amplify';
import { Dropdown } from 'primereact/dropdown';
import moment from 'moment'
import { Link } from "react-router-dom";
import { InputSwitch } from 'primereact/inputswitch';

const initialState = { title: '', discount_percent: 0, price: 0 }

function Products() {
  const [formState, setFormState] = useState(initialState);
  const [products, setProducts] = useState([]);
  const [imagen, setImagen] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const fileUploadRef = useRef(null);
  let emptyProduct = {
    title: null,
    description: null,
    image: null,
    category: null,
    price: null,
    original_price: null,
    discount_percent: null,
    organization_id: null,
    _version: null,
  };

  const [productDialog, setProductDialog] = useState(false);
  const [deleteProductDialog, setDeleteProductDialog] = useState(false);
  const [product, setProduct] = useState(emptyProduct);
  const [edit, setEdit] = useState(false);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [organizations, setOrganizations] = useState([]);
  const toast = useRef(null);
  const dt = useRef(null);


  useEffect(() => {
    fetchProducts();
  }, [])

  const categories = [
    { label: 'Motorcycle', value: 'motorcycle' },
    { label: 'Car', value: 'car' },
  ];

  const currencyOptions = [
    { label: 'Colones', value: 'CRC' },
    { label: 'Dólares', value: 'USD' },
  ];

  async function fetchProducts() {
    try {
      const organizationsData = await API.graphql(graphqlOperation(listOrganizations));
      const organizationsItems = organizationsData?.data?.listOrganizations?.items;
      setOrganizations(organizationsItems.map((org) => {
        return {
          label: org.name,
          value: org.id
        }
      } ));
      const productData = await API.graphql(graphqlOperation(listProducts))
      console.log(productData);
      let _products = productData.data.listProducts.items.filter(i => !i._deleted)
      for (var i in _products) {
        _products[i].image = await downloadImage(_products[i].image);
        _products[i].createdAtFormat = dateFormat(_products[i].createdAt);
        _products[i].category = _products[i].category[1];
        _products[i].price = _products[i].price ? _products[i].price : 0;
        const organizationProduct = organizationsItems.find(org => org.id === _products[i].organization_id)?.name;
        _products[i].organization = organizationProduct ? organizationProduct : "N/A";
      }
      console.log(_products);
      setProducts(_products);
    } catch (err) {
      console.log('error fetching products', err);
    }
  };


  const dateFormat = (date) => {
    if (typeof date === "undefined" || date === null) return date;
    return moment(date).format('DD/MM/YYYY')
  }

  const openNew = () => {
    setEdit(false);
    setProductDialog(true);
    setSubmitted(false);
  }

  const addProduct = async () => {
    setSubmitted(true);
    if (!product.title || !product.description || !product.price || !product.original_price || !product.category || !product.currency) {
      return
    }
    try {
      const _product = {
        ...product,
        category: ["all", product.category]
      };
      console.log(_product);
      const res = await API.graphql(graphqlOperation(createProduct, { input: _product }))
      console.log(res.data.createProduct);
      setProduct(emptyProduct);
      setSubmitted(false);
      setProductDialog(false);
      fetchProducts();
    } catch (err) {
      console.log('error creating product:', err)
    }
  }

  const hideDialog = () => {
    setProduct(emptyProduct);
    setProductDialog(false);
    setSubmitted(false);
  }

  const hideDeleteProductDialog = () => {
    setProduct(emptyProduct);
    setDeleteProductDialog(false);
    setSubmitted(false);
  }


  const editProduct = async (id) => {
    const productData = await API.graphql(graphqlOperation(getProduct, { id: id }));
    const _product = {
      ...productData.data.getProduct,
      category: productData.data.getProduct.category[1],
    }

    setProduct(_product);
    setEdit(true);
    setProductDialog(true);
  }

  const updateSelectedProduct = async () => {
    setSubmitted(true);
    if (!product.title || !product.description || !product.price || !product.original_price || !product.category || !product.currency) {
      return
    }
    try {
      fileUploadRef.current.upload();
      /*
      var i;
      await Storage.get(product.id+"_image.jpeg")
      .then((response) => {
        i=response;
      })
      .catch((error) => {
        console.log(error);
      });
      */
      const _product = {
        id: product.id,
        title: product.title,
        price: product.price,
        original_price: product.original_price,
        discount_percent: product.discount_percent,
        description: product.description,
        organization_id: product.organization_id,
        category: ["all", product.category],
        _version: product._version,
        currency: product.currency
      };

      await API.graphql(graphqlOperation(updateProduct, { input: _product }))

      setProductDialog(false);
      setSubmitted(false);
      setProduct(emptyProduct);
      fetchProducts();
    } catch (err) {
      console.log('error updating product:', err)
    }
  }

  const confirmDeleteProduct = async (id) => {
    const _product = await API.graphql(graphqlOperation(getProduct, { id: id }));
    setProduct(_product.data.getProduct);
    setDeleteProductDialog(true);
  }

  const deleteSelectedProduct = async () => {
    try {
      const _product = {
        id: product.id,
        _version: product._version
      }
      await API.graphql(graphqlOperation(deleteProduct, { input: _product }))
      setDeleteProductDialog(false);
      fetchProducts();
      setProduct(emptyProduct);
      //toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Product Deleted', life: 3000 });
    } catch (err) {
      console.log('error deleting product:', err)
    }
  }


  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value);
    let _product = { ...product };
    _product[`${name}`] = val;
    setProduct(_product);
  }


  const leftToolbarTemplate = () => {
    return (
      <React.Fragment>
        <Button label="New" icon="pi pi-plus" className="p-button bg-primary mr-2" onClick={openNew} />
      </React.Fragment>
    )
  }


  const imageBodyTemplate = (rowData) => {
    return <img src={rowData.image} onError={(e) => e.target.src = 'https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} alt={rowData.image} className="product-image" />
  }

  const priceBodyTemplate = (rowData) => {
    let currency = "";
    if (rowData.currency) {
      currency = rowData.currency === "USD" ? "$" : "₡";
    }
    return currency + rowData.price;
  };

  const originalPriceBodyTemplate = (rowData) => {
    let currency = "";
    if (rowData.currency) {
      currency = rowData.currency === "USD" ? "$" : "₡";
    }
    return currency + rowData.original_price;
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>

        <Button icon="pi pi-pencil" className="p-button-rounded bg-primary mr-2 " onClick={() => editProduct(rowData.id)} />
        <Button icon="pi pi-trash" className="p-button-rounded p-button-warning mr-2" onClick={() => confirmDeleteProduct(rowData.id)} />
      </React.Fragment>
    );
  }

  const header = (
    <div className="table-header">
      <h3 className="mx-0 my-1">Products</h3>
      <span className="p-input-icon-left">
        <i className="pi pi-search" />
        <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
      </span>
    </div>
  );
  const productDialogFooter = (
    <React.Fragment>
      <Button label="Cancel" icon="pi pi-times" className="p-button-text c-primary" onClick={hideDialog} />
      {!edit && <Button label="Save" icon="pi pi-check" className="p-button-text c-primary" onClick={addProduct} />}
      {edit && <Button label="Save" icon="pi pi-check" className="p-button-text c-primary" onClick={() => updateSelectedProduct()} />}
    </React.Fragment>
  );
  const deleteProductDialogFooter = (
    <React.Fragment>
      <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteProductDialog} />
      <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={() => deleteSelectedProduct()} />
    </React.Fragment>
  );
  const uploadHandler = ({ files }) => {
    const [file] = files;
    if (typeof file !== "undefined") {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = (e) => {
        handleImagePicked(e.target.result);
      };
    }
  };

  const handleImagePicked = async (uri) => {
    try {
      console.log(uri);
      const img = await fetchImageFromUri(uri);
      const uuid = require("uuid");
      const pseudoId = "image_" + uuid.v4();
      const uploadUrl = await uploadImage(pseudoId, img);
    } catch (e) {
      console.log(e);
    }
  }
  const chooseOptions = { label: 'Choose', icon: 'pi pi-fw pi-plus', className: 'bg-primary' };
  const uploadOptions = { label: 'Uplaod', icon: 'pi pi-upload', className: 'bg-primary' };
  const cancelOptions = { label: 'Cancel', icon: 'pi pi-times', className: 'bg-primary' };

  const fetchImageFromUri = async (uri) => {
    const response = await fetch(uri);
    const blob = await response.blob();
    return blob;
  };

  const uploadImage = (filename, img) => {
    Auth.currentCredentials();
    return Storage.put(filename, img, {
      level: "public",
      contentType: "image/jpeg"
    })
      .then(async (response) => {
        const _product = {
          id: product.id,
          image: response.key,
          _version: product._version
        };
        await API.graphql(graphqlOperation(updateProduct, { input: _product }))
      })
      .catch((error) => {
        console.log(error);
        return error.response;
      });
  };


  const downloadImage = (uri) => {
    return Storage.get(uri)
      .then((result) => {
        console.log(result);
        return result;
      })
      .catch((error) => {
        console.log(error);
        return error;
      });
  };


  const publishedBody = (rowData) => {
    return <InputSwitch id="published" checked={rowData.published} />;
  }

  const publishedStatus = (options) => {
    console.log(options);
    return (
      <InputSwitch checked={options.value} onChange={async (e) => {
        await onChangeStatus(e, options.rowData);
        options.editorCallback(e.value);
      }} />
    );
  }

  const onChangeStatus = async (e, dataRow) => {
    const p = products.find(product => product.id === dataRow.id);
    const _product = {
      id: p.id,
      published: e.value,
      _version: p._version
    };
    console.log(_product)
    const res = await API.graphql(graphqlOperation(updateProduct, { input: _product }))
    console.log(res);
    products.find(product => product.id === dataRow.id).published = e.value;
    //setProducts(products);
  }


  return (
    <div className="datatable-crud-demo">
      <Toast ref={toast} />

      <div className="card">
        <Toolbar className="mb-4" left={leftToolbarTemplate}></Toolbar>

        <DataTable ref={dt} value={products} editMode='cell'
          dataKey="id" paginator rows={10} rowsPerPageOptions={[10, 25, 50]}
          paginatorTemplate="PrevPageLink PageLinks NextPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
          globalFilter={globalFilter} header={header} responsiveLayout="scroll">
          <Column body={publishedBody} editor={publishedStatus} field="published" header="Published" style={{ minWidth: '8rem' }}></Column>
          <Column field="createdAtFormat" sortField="createdAt" header="Created At" sortable style={{ minWidth: '10rem' }}></Column>
          <Column field="organization" header="Organization" sortable style={{ minWidth: '10rem' }}></Column>
          <Column field="title" header="Title" sortable style={{ minWidth: '8rem' }}></Column>
          <Column field="price" sortField="price" header="Price" body={priceBodyTemplate} sortable style={{ minWidth: '8rem' }}></Column>
          <Column field="category" header="Category" sortable style={{ minWidth: '8rem' }}></Column>
          <Column field="image" header="Image" body={imageBodyTemplate}></Column>
          <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '8rem' }}></Column>
        </DataTable>

      </div>

      <Dialog visible={productDialog} style={{ width: '450px' }} header="Product" modal className="p-fluid" footer={productDialogFooter} onHide={hideDialog}>
        {//product.image && <img src={`images/product/${product.image}`} onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} alt={product.image} className="product-image block m-auto pb-3" />
        }
        <div className="field">
          <label htmlFor="title">Title</label>
          <InputText id="title" value={product.title} onChange={(e) => onInputChange(e, 'title')} className={classNames({ 'p-invalid': submitted && !product.title })} />
          {submitted && !product.title && <small className="p-error">Title is required.</small>}
        </div>
        <div className="field">
          <label htmlFor="description">Description</label>
          <InputTextarea id="description" value={product.description} onChange={(e) => onInputChange(e, 'description')} required rows={3} cols={20} className={classNames({ 'p-invalid': submitted && !product.description })} />
          {submitted && !product.description && <small className="p-error">Description is required.</small>}
        </div>

        <div className="formgrid grid">
          <div className="field col">
            <label htmlFor="original_price">Original price</label>
            <InputNumber id="original_price" value={product.original_price} onValueChange={(e) => onInputChange(e, 'original_price')} integeronly className={classNames({ 'p-invalid': submitted && !product.original_price })} />
            {submitted && !product.original_price && <small className="p-error">Original price is required.</small>}
          </div>
          <div className="field col">
            <label htmlFor="price">Price</label>
            <InputNumber id="price" value={product.price} onValueChange={(e) => onInputChange(e, 'price')} integeronly className={classNames({ 'p-invalid': submitted && !product.price })} />
            {submitted && !product.price && <small className="p-error">Price is required.</small>}
          </div>
        </div>
        <div className="formgrid grid">
          <div className="field col">
            <label htmlFor="category">Category</label>
            <Dropdown value={product.category} options={categories} onChange={(e) => onInputChange(e, 'category')} className={classNames({ 'p-invalid': submitted && !product.category })} />
            {submitted && !product.category && <small className="p-error">Category is required.</small>}
          </div>
          <div className="field col">
            <label htmlFor="currency">Currency</label>
            <Dropdown value={product.currency} options={currencyOptions} onChange={(e) => onInputChange(e, 'currency')} className={classNames({ 'p-invalid': submitted && !product.currency })} />
            {submitted && !product.currency && <small className="p-error">Currency is required.</small>}
          </div>
        </div>
        <div className="field">
          <label htmlFor="organization">Organization</label>
          <Dropdown value={product.organization_id} options={organizations} onChange={(e) => onInputChange(e, 'organization_id')} />
        </div>
        {edit &&
          <div className="field">
            <label htmlFor="image">Image</label>
            <FileUpload ref={
              fileUploadRef}
              customUpload
              uploadHandler={uploadHandler}
              multiple={false}
              name="image"
              accept="image/*"
              maxFileSize={2000000}
              chooseOptions={chooseOptions}
              cancelOptions={cancelOptions} />
          </div>}
      </Dialog>

      <Dialog visible={deleteProductDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteProductDialogFooter} onHide={hideDeleteProductDialog}>
        <div className="confirmation-content">
          <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
          {product && <span>Are you sure you want to delete <b>{product.title}</b>?</span>}
        </div>
      </Dialog>

    </div>
  );
}

export default Products;
