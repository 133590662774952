import React, { useEffect, useState, useRef } from 'react'
import { API, graphqlOperation } from 'aws-amplify'
import { createCupon, deleteCupon, updateCupon } from '../graphql/mutations';
import { listCupons, getCupon } from '../graphql/queries';
import { onCreateCupon } from '../graphql/subscriptions';
import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { Toolbar } from 'primereact/toolbar';
import { InputTextarea } from 'primereact/inputtextarea';
import { RadioButton } from 'primereact/radiobutton';
import { InputNumber } from 'primereact/inputnumber';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Auth, Storage } from 'aws-amplify';
import { Dropdown } from 'primereact/dropdown';
import moment from 'moment'
import {Link} from "react-router-dom";

const initialState = { title: '', discount_percent: 0, price: 0 }

function Cupons() {
  const [formState, setFormState] = useState(initialState);
  const [cupons, setCupons] = useState([]);
  const [imagen, setImagen] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const fileUploadRef = useRef(null);
  let emptyCupon = {
    title: null,
    description: null,
    image: null,
    category: null,
    price: null,
    original_price: null,
    discount_percent: null,
    _version: null,
  };

  const [cuponDialog, setCuponDialog] = useState(false);
  const [deleteCuponDialog, setDeleteCuponDialog] = useState(false);
  const [cupon, setCupon] = useState(emptyCupon);
  const [edit, setEdit] = useState(false);
  const [globalFilter, setGlobalFilter] = useState(null);
  const toast = useRef(null);
  const dt = useRef(null);


  useEffect(() => {
    fetchCupons();
   }, [])


  async function fetchCupons() {
    try {
      const cuponData = await API.graphql(graphqlOperation(listCupons))
      let _cupons = cuponData.data.listCupons.items.filter(i => !i._deleted)

      for(var i in _cupons){
        _cupons[i].image=await downloadImage(_cupons[i].id+"_image.jpeg");
        _cupons[i].category=_cupons[i].category[1];
        _cupons[i].createdAtFormat=dateFormat(_cupons[i].createdAt);
      }
      console.log(_cupons);
      setCupons(_cupons);
    } catch (err) { 
      console.log('error fetching cupons');
      console.log(err);
    }
  };

  const categories = [
    { label: 'Mechanic', value: 'mechanic' },
    { label: 'Food', value: 'food' },
    { label: 'Entertainment', value: 'entertainment' },
    { label: 'Self-Care', value: 'self-care' },
];

    const dateFormat=(date)=>{
        if (typeof date ==="undefined" || date ===null ) return date; 
        return moment(date).format('DD/MM/YYYY')
    }

  const openNew = () => {
    setEdit(false);
    setCuponDialog(true);
    setSubmitted(false);
  }

  const addCupon = async () => {    
    setSubmitted(true);
    if(!cupon.title || !cupon.description || !cupon.category ){
      return 
    }
    try {
      const _cupon={
        ...cupon,
        category: ["all", cupon.category]
      };
      console.log(_cupon);
      const res = await API.graphql(graphqlOperation(createCupon, {input: _cupon}))
      console.log(res.data.createCupon);
      //fileUploadRef.current.upload();
      setCupon(emptyCupon);
      setCuponDialog(false);
      setSubmitted(false);
      fetchCupons();
    } catch (err) {
      console.log('error creating cupon:', err)
    }      
  }

  const hideDialog = () => {
    setCupon(emptyCupon);
    setCuponDialog(false);
    setSubmitted(false);
  }

  const hideDeleteCuponDialog = () => {
    setCupon(emptyCupon);    
    setDeleteCuponDialog(false);
    setSubmitted(false);
  }


  const editCupon = async (id) => {
    const cuponData=await API.graphql(graphqlOperation(getCupon, {id: id}));
    const _cupon={
      ...cuponData.data.getCupon,
      category:cuponData.data.getCupon.category[1],
    }
//    setImagen(_cupon.image);
    setCupon(_cupon);
    setEdit(true);
    setCuponDialog(true);
  }

  const updateSelectedCupon = async () => {
    setSubmitted(true);
    if(!cupon.title || !cupon.description || !cupon.category ){
      return 
    }
    try {
      fileUploadRef.current.upload();
      /*
      var i;
      await Storage.get(cupon.id+"_image.jpeg")
      .then((response) => {
        i=response;
      })
      .catch((error) => {
        console.log(error);
      });
      */
      const _cupon = {
        id: cupon.id,
        title: cupon.title,
        category: ["all",cupon.category],
        price: cupon.price,
        original_price: cupon.original_price,
        discount_percent: cupon.discount_percent,
        description: cupon.description,
        _version: cupon._version,
        };

      await API.graphql(graphqlOperation(updateCupon, {input: _cupon}))
      
      setCuponDialog(false);
      setCupon(emptyCupon);
      setSubmitted(false);
      fetchCupons();
    } catch (err) {
      console.log('error updating cupon:', err)
    } 
  }

  const confirmDeleteCupon = async (id) => {
    const _cupon=await API.graphql(graphqlOperation(getCupon, {id: id}));
    setCupon(_cupon.data.getCupon);    
    setDeleteCuponDialog(true);
  }

  const deleteSelectedCupon = async () => {
    try{
      const _cupon={
        id:cupon.id,
        _version:cupon._version
      }
      await API.graphql(graphqlOperation(deleteCupon, {input: _cupon}))
      setDeleteCuponDialog(false);
      fetchCupons();
      setCupon(emptyCupon);
      //toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Cupon Deleted', life: 3000 });
    }catch (err) {
      console.log('error deleting cupon:', err)
    }   
  }


  const onInputChange = (e, name) => {
      const val = (e.target && e.target.value);
      let _cupon = {...cupon};
      _cupon[`${name}`] = val;
      setCupon(_cupon);
  }


  const leftToolbarTemplate = () => {
    return (
        <React.Fragment>
            <Button label="New" icon="pi pi-plus" className="p-button bg-primary mr-2" onClick={openNew} />
        </React.Fragment>
    )
}


  const imageBodyTemplate = (rowData) => {
      return <img src={rowData.image} onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} alt={rowData.image} className="product-image" />
  }

  const priceBodyTemplate = (rowData) => {
      return "₡"+rowData.price;
  };


  const actionBodyTemplate = (rowData) => {
      return (
          <React.Fragment>
              <Button icon="pi pi-pencil" className="p-button-rounded bg-primary mr-2 " onClick={() => editCupon(rowData.id)} />
              <Link to={`/locations/${rowData.id}`}>
                <Button icon="pi pi-map-marker" className="p-button-rounded button-location p-button-danger mr-2"/>
              </Link>
              <Button icon="pi pi-trash" className="p-button-rounded p-button-warning mr-2" onClick={() => confirmDeleteCupon(rowData.id)} />
          </React.Fragment>
      );
  }

  const header = (
      <div className="table-header">
          <h3 className="mx-0 my-1">Cupons</h3>
          <span className="p-input-icon-left">
              <i className="pi pi-search" />
              <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
          </span>
      </div>
  );
  const cuponDialogFooter = (
      <React.Fragment>
          <Button label="Cancel" icon="pi pi-times" className="p-button-text c-primary" onClick={hideDialog} />
          {!edit && <Button label="Save" icon="pi pi-check" className="p-button-text c-primary" onClick={addCupon} />}
          {edit &&<Button label="Save" icon="pi pi-check" className="p-button-text c-primary" onClick={()=> updateSelectedCupon()} />}
      </React.Fragment>
  );
  const deleteCuponDialogFooter = (
      <React.Fragment>
          <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteCuponDialog} />
          <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={() => deleteSelectedCupon()} />
      </React.Fragment>
  );
  const uploadHandler = ({files}) => { 
    const [file] = files;
    if (typeof file !=="undefined") {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = (e) => {
        handleImagePicked(e.target.result);
      };
    }
  };

  const handleImagePicked = async(uri)=>{
    try{
      console.log(uri);
      const img = await fetchImageFromUri(uri);
      const filename = cupon.id + "_image.jpeg";
      const uploadUrl = await uploadImage(filename, img);
      console.log(uploadUrl);
    }catch (e) {
      console.log(e);
    }        
  }
  const chooseOptions = {label: 'Choose', icon: 'pi pi-fw pi-plus',className: 'bg-primary'};
  const uploadOptions = {label: 'Uplaod', icon: 'pi pi-upload', className: 'bg-primary'};
  const cancelOptions = {label: 'Cancel', icon: 'pi pi-times', className: 'bg-primary'};  

  const fetchImageFromUri = async (uri) => {
    const response = await fetch(uri);
    const blob = await response.blob();
    return blob;
  };    

  const uploadImage = (filename, img) => {
    Auth.currentCredentials();
    return Storage.put(filename, img, {
      level: "public",
      contentType: "image/jpeg",
      progressCallback(progress) {
        //setLoading(progress);
      },
    })
      .then((response) => {
        return response.key;
      })
      .catch((error) => {
        console.log(error);
        return error.response;
      });
  };
  

  const downloadImage = (uri) => {
    return Storage.get(uri)
    .then((result) => {
      console.log(result);
      return result;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
  };


  return (
      <div className="datatable-crud-demo">
      <Toast ref={toast} />

      <div className="card">
          <Toolbar className="mb-4" left={leftToolbarTemplate}></Toolbar>

          <DataTable ref={dt} value={cupons}
                dataKey="id" paginator rows={10} rowsPerPageOptions={[ 10, 25, 50]}
                paginatorTemplate="PrevPageLink PageLinks NextPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} cupons"
              globalFilter={globalFilter} header={header} responsiveLayout="scroll">
              <Column field="createdAtFormat" sortField="createdAt" header="Created At" sortable style={{ minWidth: '10rem' }}></Column>  
              {/* <Column field="id" header="Id" sortable style={{ maxWidth: '10rem' }}></Column> */}
              <Column field="title" header="Title" sortable style={{ minWidth: '8rem' }}></Column>              
              <Column field="price" header="Price" body={priceBodyTemplate} sortable style={{ minWidth: '8rem' }}></Column>
              <Column field="category" header="Category"  sortable style={{ minWidth: '8rem' }}></Column>                            
              <Column field="image" header="Image" body={imageBodyTemplate}></Column>
              <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '8rem' }}></Column>
          </DataTable>
          
      </div>

      <Dialog visible={cuponDialog} style={{ width: '450px' }} header="Cupon" modal className="p-fluid" footer={cuponDialogFooter} onHide={hideDialog}>
          {//product.image && <img src={`images/product/${product.image}`} onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} alt={product.image} className="product-image block m-auto pb-3" />
          }
          <div className="field">
              <label htmlFor="title">Title</label>
              <InputText id="title" value={cupon.title} onChange={(e) => onInputChange(e, 'title')} className={classNames({ 'p-invalid': submitted && !cupon.title })} />
              {submitted && !cupon.title && <small className="p-error">Title is required.</small>}
          </div>
          <div className="field">
              <label htmlFor="description">Description</label>
              <InputTextarea id="description" value={cupon.description} onChange={(e) => onInputChange(e, 'description')} required rows={3} cols={20} className={classNames({ 'p-invalid': submitted && !cupon.description })} />
              {submitted && !cupon.description && <small className="p-error">Description is required.</small>}
          </div>

          <div className="formgrid grid">
              <div className="field col">
                  <label htmlFor="original_price">Original price*</label>
                  <InputNumber id="original_price" value={cupon.original_price} onValueChange={(e) => onInputChange(e, 'original_price')} integeronly />
              </div>
              <div className="field col">
                  <label htmlFor="discount_percent">Discount percent*</label>
                  <InputNumber id="discount_percent" value={cupon.discount_percent} onValueChange={(e) => onInputChange(e, 'discount_percent')} integeronly />
              </div>
          </div>
          <div className="formgrid grid">
              <div className="field col">
                  <label htmlFor="price">Price*</label>
                  <InputNumber id="price" value={cupon.price} onValueChange={(e) => onInputChange(e, 'price')} integeronly  />
              </div>
              <div className="field col">
                  <label htmlFor="category">Category</label>
                  <Dropdown value={cupon.category} options={categories}  onChange={(e) => onInputChange(e, 'category')} className={classNames({ 'p-invalid': submitted && !cupon.category })} />
              {submitted && !cupon.category && <small className="p-error">Category is required.</small>}
              </div>
          </div>
          {edit &&
          <div className="field">
              <label htmlFor="image">Image</label>
              <FileUpload ref={
                fileUploadRef} 
                customUpload 
                uploadHandler={uploadHandler} 
                multiple={false} 
                name="image" 
                accept="image/*" 
                maxFileSize={1000000}
                chooseOptions={chooseOptions} 
                uploadOptions={uploadOptions} 
                cancelOptions={cancelOptions} />
          </div>}
      </Dialog>

      <Dialog visible={deleteCuponDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteCuponDialogFooter} onHide={hideDeleteCuponDialog}>
          <div className="confirmation-content">
              <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem'}} />
              {cupon && <span>Are you sure you want to delete <b>{cupon.title}</b>?</span>}
          </div>
      </Dialog>

  </div>
  );
}

export default Cupons;
