import { API, graphqlOperation } from 'aws-amplify';
import { Button } from 'primereact/button';
import React from 'react';
import { listEarnings, listEvents, listPlatformProfiles } from '../graphql/queries';
import $ from 'jquery';
import { json2csv } from 'json-2-csv';

export const DBDump = () => {

    const [loadingEarnings, setLoadingEarnings] = React.useState(false);
    const [loadingDownloadEarnings, setLoadingDownloadEarnings] = React.useState(false);
    const [loadingEvents, setLoadingEvents] = React.useState(false);
    const [loadingDownloadEvents, setLoadingDownloadEvents] = React.useState(false);
    const [loadingPlatforms, setLoadingPlatforms] = React.useState(false);
    const [loadingDownloadPlatforms, setLoadingDownloadPlatforms] = React.useState(false);
    const [error, setError] = React.useState(null);
    const [earings, setEarnings] = React.useState(null);
    const [events, setEvents] = React.useState(null);
    const [platformProfiles, setPlatformProfiles] = React.useState(null);

    const JSONtoCSV = (json, ReportTitle) => {
        json2csv(json, (err, csv) => {
            //this trick will generate a temp "a" tag
            var link = document.createElement("a");
            link.id = "lnkDwnldLnk";
        
            //this part will append the anchor tag and remove it after automatic click
            document.body.appendChild(link);
        
            var blob = new Blob([csv], { type: 'text/csv' });
            var csvUrl = window.webkitURL.createObjectURL(blob);
            var filename =  (ReportTitle || 'UserExport') + '.csv';
            $("#lnkDwnldLnk")
                .attr({
                    'download': filename,
                    'href': csvUrl
                });
        
            $('#lnkDwnldLnk')[0].click();
            document.body.removeChild(link);
        });
    }
    
    const JSONToCSVConvertor = (JSONData, ReportTitle, ShowLabel) => {

        //If JSONData is not an object then JSON.parse will parse the JSON string in an Object
        var arrData = typeof JSONData != 'object' ? JSON.parse(JSONData) : JSONData;
        var CSV = '';
        //This condition will generate the Label/Header
        if (ShowLabel) {
            var row = "";
    
            //This loop will extract the label from 1st index of on array
            for (var index in arrData[0]) {
                //Now convert each value to string and comma-seprated
                row += JSON.stringify(index) + ',';
            }
            row = row.slice(0, -1);
            //append Label row with line break
            CSV += row + '\r\n';
        }
    
        //1st loop is to extract each row
        for (var i = 0; i < arrData.length; i++) {
            var row = "";
            //2nd loop will extract each column and convert it in string comma-seprated
            for (var index in arrData[i]) {
                row += '"' + arrData[i][index] + '",';
            }
            row.slice(0, row.length - 1);
            //add a line break after each row
            CSV += row + '\r\n';
        }
    
        if (CSV == '') {
            alert("Invalid data");
            return;
        }
    
        //this trick will generate a temp "a" tag
        var link = document.createElement("a");
        link.id = "lnkDwnldLnk";
    
        //this part will append the anchor tag and remove it after automatic click
        document.body.appendChild(link);
    
        var csv = CSV;
        var blob = new Blob([csv], { type: 'text/csv' });
        var csvUrl = window.webkitURL.createObjectURL(blob);
        var filename =  (ReportTitle || 'UserExport') + '.csv';
        $("#lnkDwnldLnk")
            .attr({
                'download': filename,
                'href': csvUrl
            });
    
        $('#lnkDwnldLnk')[0].click();
        document.body.removeChild(link);
    }

    async function fetchEarnings() {
        setLoadingEarnings(true);
        setError(null);
        try {
            let nextToken = '';
            let allEarnings = [];
            while(nextToken !== null) {
                const earningsData = await API.graphql(graphqlOperation(listEarnings, nextToken !== '' ? { limit: 1000, nextToken } : { limit: 1000 }));
                const _earnings = earningsData.data.listEarnings.items;
                nextToken = earningsData.data.listEarnings.nextToken ? earningsData.data.listEarnings.nextToken : null;
                allEarnings = [...allEarnings, ..._earnings]
            }
            setEarnings(allEarnings);
        } catch (err) {
            setError(err);
        }
        setLoadingEarnings(false);
    }

    async function fetchEvents() {
        setLoadingEvents(true);
        setError(null);
        try {
            let nextToken = '';
            let allEvents = [];
            while(nextToken !== null) {
                const eventsData = await API.graphql(graphqlOperation(listEvents, nextToken !== '' ? { limit: 1000, nextToken } : { limit: 1000 }));
                const _events = eventsData.data.listEvents.items;
                nextToken = eventsData.data.listEvents.nextToken ? eventsData.data.listEvents.nextToken : null;
                allEvents = [...allEvents, ..._events]
            }
            setEvents(allEvents);
        } catch (err) {
            setError(err);
        }
        setLoadingEvents(false);
    }

    async function fetchPlatforms() {
        setLoadingPlatforms(true);
        setError(null);
        try {
            let nextToken = '';
            let allPlatforms = [];
            while(nextToken !== null) {
                const platformsData = await API.graphql(graphqlOperation(listPlatformProfiles, nextToken !== '' ? { limit: 1000, nextToken } : { limit: 1000 }));
                const _platforms = platformsData.data.listPlatformProfiles.items;
                nextToken = platformsData.data.listPlatformProfiles.nextToken ? platformsData.data.listPlatformProfiles.nextToken : null;
                allPlatforms = [...allPlatforms, ..._platforms]
            }
            setPlatformProfiles(allPlatforms);
        } catch (err) {
            setError(err);
        }
        setLoadingPlatforms(false);
    }
    
  return (
    <div style={{ textAlign: 'left' }}>
        <div style={{ marginBottom: 30, display: 'flex', alignItems: 'center' }}>
            {!platformProfiles && <Button style={{ display: 'block', minWidth: 300 }} loading={loadingPlatforms} onClick={fetchPlatforms}>Fetch Plataforms</Button>}
            {platformProfiles && !loadingPlatforms && <Button style={{ display: 'block', minWidth: 300, background: '#2dce89' }} loading={loadingDownloadPlatforms} onClick={() => {
                setLoadingDownloadPlatforms(true);
                JSONtoCSV(platformProfiles, 'Platforms');
                setLoadingDownloadPlatforms(false);
            }}>Download Platforms</Button>}
            {platformProfiles && <span style={{ marginLeft: 20 }}>{platformProfiles.length} Platform Profiles</span>}
        </div>
        <div style={{ marginBottom: 30, display: 'flex', alignItems: 'center' }}>
            {!earings && <Button style={{ display: 'block', minWidth: 300 }} loading={loadingEarnings} onClick={fetchEarnings}>Fetch Earnings</Button>}
            {earings && !loadingEarnings && <Button style={{ display: 'block', minWidth: 300, background: '#2dce89' }} loading={loadingDownloadEarnings} onClick={() => {
                setLoadingDownloadEarnings(true);
                JSONtoCSV(earings, 'Earnings');
                setLoadingDownloadEarnings(false);
            }}>Download Earnings</Button>}
            {earings && <span style={{ marginLeft: 20 }}>{earings.length} Earnings</span>}
        </div>
        <div style={{ marginBottom: 30, display: 'flex', alignItems: 'center' }}>
            {!events && <Button style={{ display: 'block', minWidth: 300 }} loading={loadingEvents} onClick={fetchEvents}>Fetch Events</Button>}
            {events && !loadingEvents && <Button style={{ display: 'block', minWidth: 300, background: '#2dce89' }} loading={loadingDownloadEvents} onClick={() => {
                setLoadingDownloadEvents(true);
                JSONtoCSV(events, 'Events');
                setLoadingDownloadEvents(false);
            }}>Download Events</Button>}
            {events && <span style={{ marginLeft: 20 }}>{events.length} Events</span>}
        </div>
        {error && <span>{error}</span>}
    </div>
  )
}
