import React, { useState, useEffect, useRef } from 'react';
import { API, graphqlOperation } from 'aws-amplify'
import { connectionsByDate, listConnectionAttempts } from '../graphql/queries';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import moment from 'moment'
import { InputText } from 'primereact/inputtext';
import { Chart } from 'primereact/chart';
import { getAppSetting } from '../graphql/queries';

function ConnectionAttempts(){
 
    const [connectionAttempts, setConnectionAttempts] = useState(null);
    const [globalFilter, setGlobalFilter] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);
    const [chartSuccessful, setCharSuccess] = useState({labels:[],datasets:[]});
    const [chartFailed, setCharFail] = useState({labels:[],datasets:[]});
    const [lightOptions] = useState({
        plugins: {
            legend: {
                labels: {
                    color: '#495057'
                }
            }
        }
    });

    useEffect(() => {
        fetchConnectionAttempts();
    }, []); 

    async function fetchConnectionAttempts() {
        try {
            let nextToken = '';
            let connections = [];
            while(nextToken !== null) {
                const connectionsData = await API.graphql(graphqlOperation(listConnectionAttempts, nextToken !== '' ? { limit: 1000, nextToken } : { limit: 1000 }));
                const _connections = connectionsData.data.listConnectionAttempts.items;
                nextToken = connectionsData.data.listConnectionAttempts.nextToken;
                _connections.forEach(function(part,index){         
                  this[index].createdAtFormat=dateFormat(this[index].createdAt);
                }, _connections)
                connections = [...connections, ..._connections]
            }
            setConnectionAttempts(connections)
            
            //let successfulConnections = _connections.filter(i => i.type=="PALENCA LINK" && i.status=="CONFIRMED")
            //let failedConnections = _connections.filter(i => i.type=="PALENCA VERIFY" && i.status=="FAILED")
            //console.log("successfulConnections",successfulConnections);
            //console.log("failedConnections",failedConnections);

            const platforms=[];
            const successfulData = [];
            const failedData = [];

            const appSetts = await API.graphql(graphqlOperation(getAppSetting,{id: "app_settings_id"}));

            appSetts.data.getAppSetting.platform_availability.forEach((appSettsPlatform)=>{
                platforms.push(appSettsPlatform.platform);
                const successDataPlat = connections.filter(i => i.type=="PALENCA LINK" && i.status=="CONFIRMED" && i.platform == appSettsPlatform.platform);
                const failedDataPlat = connections.filter(i => i.type=="PALENCA VERIFY" && i.status=="FAILED" && i.platform == appSettsPlatform.platform);
                successfulData.push(successDataPlat.length);
                failedData.push(failedDataPlat.length);
            });

            const backgroundColor = [
                "#56e2cf",
                "#5668e2",
                "#8a56e2",
                "#e256ae",
                "#e25668",
                "#e2cf56",
                "#aee256",
                "#56e289",
            ];

            const hoverBackgroundColor = [
                "#67e5d4",
                "#6777e5",
                "#9667e5",
                "#e567b6",
                "#e56777",
                "#e5d467",
                "#b6e567",
                "#67e595",
            ];

            console.log("successfulData",successfulData);
            console.log("failedData",failedData);


            const success = {
                labels: platforms,
                datasets: [
                    {
                        data: successfulData,
                        backgroundColor:backgroundColor,
                        hoverBackgroundColor: hoverBackgroundColor
                    }
                ]
            }

            const fail = {
                labels: platforms,
                datasets: [
                    {
                        data: failedData,
                        backgroundColor:backgroundColor,
                        hoverBackgroundColor: hoverBackgroundColor
                    }
                ]
            }

            setCharSuccess(success);
            setCharFail(fail);


        } catch (err) { 
            console.log('error fetching connections',err);
        }
      }


    const dateFormat=(date)=>{
        if (typeof date ==="undefined" || date ===null ) return date; 
        return moment(date).format('DD/MM/YYYY')
    }

    const header = (
        <div className="table-header">
            <h3>ConnectionAttempts</h3>
            {/* <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
            </span> */}
        </div>
    );

    const actionBodyTemplate = (data) => (<div>
        <h5 style={{ color: data.status === 'CONFIRMED' ? 'green' : (data.status === 'PENDING' ? 'yellow' : 'red')}}>{data.status}</h5>
    </div>)

    const actionBodyDataTemplate = (data) => (<div>
        <div style={{ maxHeight: 100, overflow: 'scroll'}}>{data.data}</div>
    </div>)
    
    return (
        <div className="datatable-crud-demo">
            <Toast ref={toast} />

            <div className="card"> 
                <DataTable ref={dt} value={connectionAttempts}
                dataKey="id" paginator rows={10} rowsPerPageOptions={[ 10, 25, 50]}
                paginatorTemplate="PrevPageLink PageLinks NextPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} users"
                globalFilter={globalFilter} header={header} responsiveLayout="scroll">
                    <Column field="createdAtFormat" sortField="createdAt" header="Created At" sortable style={{ minWidth: '10rem' }}></Column>
                    <Column field="platform" header="Plataforma" sortable style={{ minWidth: '12rem' }}></Column>
                    <Column field="type" header="Tipo" sortable style={{ minWidth: '12rem' }}></Column>
                    <Column field="description" header="Description" sortable style={{ minWidth: '8rem' }} ></Column>                    
                    <Column body={actionBodyTemplate} header="Status" exportable={false} style={{ minWidth: '8rem' }}></Column>
                    {/* <Column field="status" header="Status" sortable style={{ minWidth: '8rem' }} ></Column>                     */}
                    <Column body={actionBodyDataTemplate} header="Data" sortable style={{ minWidth: '8rem' }} ></Column>                    
                    {/* <Column field="next_payment_date" header="Next Payment" style={{ minWidth: '10rem' }}></Column> */}
                </DataTable>
            </div>
            <div style={{display:"flex",flexDirection:"row",justifyContent:"space-around",backgroundColor:"#ffffff"}}>
                <div style={{width:"35%"}} >
                    <h3 >Successful connections: {chartSuccessful.datasets[0]? chartSuccessful.datasets[0].data.reduce((a, b) => a + b, 0):0}</h3>
                    <Chart type="pie" data={chartSuccessful} options={lightOptions} style={{width: '100%', }} />
                </div>
                <div style={{width:"35%"}} >
                    <h3 >Failed connections: {chartFailed.datasets[0]? chartFailed.datasets[0].data.reduce((a, b) => a + b, 0):0}</h3>
                    <Chart type="pie" data={chartFailed} options={lightOptions} style={{ width: '100%' }} />
                </div>
            </div>
        </div>
    );
}
export default ConnectionAttempts;
  