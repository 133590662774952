import React, { useState, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { InputSwitch } from 'primereact/inputswitch';
import 'dotenv/config';
import { getAppSetting } from '../graphql/queries';
import { API, graphqlOperation } from 'aws-amplify';
import {updateAppSetting} from '../graphql/mutations';


function Modules(){
    const [modules, setModules] = useState(null);


    useEffect(() => {
        console.log(process.env);
        fetchModules();
    }, []); 

    const fetchModules = async () => {   
        const appSetts = await API.graphql(graphqlOperation(getAppSetting,{id: "app_settings_id"}));
        setModules(appSetts.data.getAppSetting.module_availability);
    }



    const statusBody = (rowData) => {
        return <InputSwitch id="online" checked={rowData.online} disabled />;
    }

    const statusEditor = (options) => {
        console.log(options);
        return (
                <InputSwitch checked={options.value} onChange={(e)=>{
                    onChangeStatus(e, options.rowIndex);
                    options.editorCallback(e.value);
                }} />
        );
    }

    const onChangeStatus = (e, index) => {
        let val = e.value;
        let module = modules;
        module[index]={...module[index], online:val}
        console.log(module);
        setModules(module);
    }

    const textEditor = (options) => {
        return (
            <InputText type="text" value={options.value} onChange={(e) => {
                onChangeText(e.target, options.rowIndex);
                options.editorCallback(e.target.value);
            }}/>
        );
    }
    
    const onChangeText = (e, index) => {
        let val = e.value;
        let module = modules;
        module[index]={...module[index], online:val}
        console.log(module);
        setModules(module);
    }

    const onRowEditComplete = async () => {
        try {
            const appSetts = await API.graphql(graphqlOperation(getAppSetting,{id: "app_settings_id"}));
            let setts = appSetts.data.getAppSetting;
            setts={id: setts.id, module_availability: modules, _version: setts._version};
            console.log(setts);
            await API.graphql(graphqlOperation(updateAppSetting, {input:setts}));
            fetchModules();            
        } catch (error) {
            console.log(error);
            fetchModules();
        }
    }

    const onRowEditCancel = () => {
        fetchModules();
    }

    return (
        <div>
            <div className="card p-fluid">
                <DataTable value={modules} editMode="row" onRowEditCancel={onRowEditCancel} onRowEditComplete={onRowEditComplete} responsiveLayout="scroll">
                    <Column field="module" header="Module" editor={(options) => textEditor(options)} style={{ width: '20%' }}></Column>
                    <Column field="online" header="Online" body={statusBody} editor={statusEditor} style={{ width: '20%' }}></Column>
                    <Column rowEditor headerStyle={{ width: '10%', minWidth: '8rem' }} bodyStyle={{ textAlign: 'center' }}></Column>
                </DataTable>
            </div>
        </div>
    );
}

export default Modules;
