import React, { useEffect, useState, useRef } from 'react'
import { API, graphqlOperation } from 'aws-amplify'
import { createCupon, deleteCupon, updateCupon } from '../graphql/mutations';
import { getWorker, listPlatformProfiles , listOrders, listProducts, listWorkers} from '../graphql/queries';
import { onCreateCupon } from '../graphql/subscriptions';
import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { Toolbar } from 'primereact/toolbar';
import { InputTextarea } from 'primereact/inputtextarea';
import { RadioButton } from 'primereact/radiobutton';
import { InputNumber } from 'primereact/inputnumber';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Auth, Storage } from 'aws-amplify';
import { Dropdown } from 'primereact/dropdown';
import moment from 'moment'
import {Link} from "react-router-dom";

const initialState = { title: '', discount_percent: 0, price: 0 }

function Orders() {
  const [formState, setFormState] = useState(initialState);
  const [orders, setOrders] = useState([]);
  const [imagen, setImagen] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const fileUploadRef = useRef(null);
  let emptyOrder = {
    type: null,
    worker_id: null,
    product_id: null,
  };

  const [orderDialog, setOrderDialog] = useState(false);
  const [deleteOrderDialog, setDeleteOrderDialog] = useState(false);
  const [order, setOrder] = useState(emptyOrder);
  const [edit, setEdit] = useState(false);
  const [globalFilter, setGlobalFilter] = useState(null);
  const toast = useRef(null);
  const dt = useRef(null);


  useEffect(() => {
    fetchOrders();
   }, [])

   const exportCSV = () => {
    dt.current.exportCSV();
  }

  const rightToolbarTemplate = () => {
    return (
        <React.Fragment>
            <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={exportCSV} />
        </React.Fragment>
    )
}

  async function fetchOrders() {
    try {
      const ordersData = await API.graphql(graphqlOperation(listOrders, { limit: 1000 }));
      let _orders = ordersData.data.listOrders.items.filter(i => !i._deleted);

      const productsData = await API.graphql(graphqlOperation(listProducts, { limit: 1000}));

      const workersData = await API.graphql(graphqlOperation(listWorkers, { limit: 1000}));
      //console.log(productsData.data.listProducts.items[0])      

      for(var i in _orders){
        _orders[i].createdAtFormat=dateFormat(_orders[i].createdAt);
        
        const productData=productsData.data.listProducts.items.filter(x => x.id==_orders[i].product_id)[0];
        if(productData){
          _orders[i].product_image=await downloadImage(productData.id+"_image.jpeg");
          _orders[i].product_title=productData.title;
        }
                
        const workerData=workersData.data.listWorkers.items.filter(x => x.id==_orders[i].worker_id)[0];
        if(workerData){
          _orders[i].worker_name=workerData.given_name + " " + workerData.family_name;
        }
        try {
          const platforms=await API.graphql(graphqlOperation(listPlatformProfiles, { filter: { worker_id: {eq: _orders[i].worker_id }}, limit: 1000 }));
          if (platforms.data.listPlatformProfiles.items.length>0){
            _orders[i].platforms=platforms.data.listPlatformProfiles.items;
            _orders[i].platforms_connected=true;
          }else{
            _orders[i].platforms_connected=false;
          }
      } catch(err) {
          console.log('error getting platforms', err);
      }
        
        //const workerData=await API.graphql(graphqlOperation(getWorker, {id: _orders[i].worker_id}));
        //_orders[i].worker_name=workerData.data.getWorker.given_name + " " + workerData.data.getWorker.family_name;

      }

      console.log(_orders);
      //console.log(_products);
      setOrders(_orders);
    } catch (err) { 
      console.log('error fetching orders');
      console.log(err);
    }
  };


    const dateFormat=(date)=>{
        if (typeof date ==="undefined" || date ===null ) return date; 
        return moment(date).format('DD/MM/YYYY')
    }


  const hideDeleteOrderDialog = () => {
    setOrder(emptyOrder);    
    setDeleteOrderDialog(false);
    setSubmitted(false);
  }

  
  const deleteSelectedOrder = async () => {
    try{
      const _order={
        id:order.id,
        _version:order._version
      }
      //await API.graphql(graphqlOperation(deleteOrder, {input: _order}))
      setDeleteOrderDialog(false);
      fetchOrders();
      setOrder(emptyOrder);
      //toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Order Deleted', life: 3000 });
    }catch (err) {
      console.log('error deleting order:', err)
    }   
  }



  const imageBodyTemplate = (rowData) => {
      return <img src={rowData.product_image} onError={(e) => e.target.src='https://www.primefaces.org/wp-content/uploads/2020/05/placeholder.png'} alt={rowData.image} className="product-image" />
  }


  const actionBodyTemplate = (rowData) => {
      return (
          <React.Fragment>
                <Link to={`/individualEarnings/${rowData.worker_id}`}>
                    <Button icon="pi pi-dollar" className="p-button-rounded p-button-success"  />
                </Link>
                {
                //<Button icon="pi pi-trash" className="p-button-rounded p-button-warning mr-2" onClick={() => confirmDeleteOrder(rowData.id)} />
                }

          </React.Fragment>
      );
  }

  const header = (
      <div className="table-header">
          <h3 className="mx-0 my-1">Orders</h3>
          <span className="p-input-icon-left">
              <i className="pi pi-search" />
              <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
          </span>
      </div>
  );

  const deleteOrderDialogFooter = (
      <React.Fragment>
          <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteOrderDialog} />
          <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={() => deleteSelectedOrder()} />
      </React.Fragment>
  );

  
  const downloadImage = (uri) => {
    return Storage.get(uri)
    .then((result) => {
      //console.log(result);
      return result;
    })
    .catch((error) => {
      //console.log(error);
      return error;
    });
  };


  return (
      <div className="datatable-crud-demo">
      <Toast ref={toast} />

      <div className="card">
        <Toolbar className="mb-4" right={rightToolbarTemplate}></Toolbar>
          <DataTable ref={dt} value={orders}
                dataKey="id" paginator rows={10} rowsPerPageOptions={[ 10, 25, 50]}
                paginatorTemplate="PrevPageLink PageLinks NextPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} orders"
              globalFilter={globalFilter} header={header} responsiveLayout="scroll">
              <Column field="createdAtFormat" sortField="createdAt" header="Created At" sortable style={{ minWidth: '8rem' }}></Column>  
              {/* <Column field="id" header="Id" sortable style={{ maxWidth: '10rem' }}></Column> */}
              <Column field="type" header="Type" style={{ minWidth: '8rem' }}></Column>                            
              <Column field="worker_id" header="Worker ID" style={{ minWidth: '8rem' }}></Column>              
              {<Column field="worker_name" header="Worker Name" style={{ minWidth: '8rem' }}></Column>}
              <Column body={(item) => {
                    // return <div>{item.platforms ? item.platforms.map(p =><div>{p.name}</div>)}</div>
                        return <div>{item.platforms ? item.platforms.map(p =><div>{p.platform}</div>) : ''}</div>
                    }} header="Platforms" sortable exportable={true} style={{ minWidth: '12rem' }}></Column>
              <Column field="platforms_connected" header="Platforms" hidden={true} ></Column>
              <Column field="product_id" header="Product ID" style={{ minWidth: '8rem' }}></Column>
              <Column field="product_title" header="Product Title" style={{ minWidth: '8rem' }}></Column>
              <Column field="product_image" header="Product Image" body={imageBodyTemplate}></Column>
              <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '8rem' }}></Column>
          </DataTable>
          
      </div>

      <Dialog visible={deleteOrderDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteOrderDialogFooter} onHide={hideDeleteOrderDialog}>
          <div className="confirmation-content">
              <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem'}} />
              {order && <span>Are you sure you want to delete <b>{order.title}</b>?</span>}
          </div>
      </Dialog>

  </div>
  );
}

export default Orders;
