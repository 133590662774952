/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const registerWorkerOnLoymark = /* GraphQL */ `
  mutation RegisterWorkerOnLoymark($wallet_id: String) {
    registerWorkerOnLoymark(wallet_id: $wallet_id)
  }
`;
export const createPalencaPlatformProfile = /* GraphQL */ `
  mutation CreatePalencaPlatformProfile(
    $platform: String
    $email: String
    $password: String
    $phone_number: String
    $platform_id: String
  ) {
    createPalencaPlatformProfile(
      platform: $platform
      email: $email
      password: $password
      phone_number: $phone_number
      platform_id: $platform_id
    ) {
      success
      message
    }
  }
`;
export const verifyPalencaPlatformProfile = /* GraphQL */ `
  mutation VerifyPalencaPlatformProfile(
    $platform: String
    $code: String
    $phone_number: String
    $password: String
  ) {
    verifyPalencaPlatformProfile(
      platform: $platform
      code: $code
      phone_number: $phone_number
      password: $password
    ) {
      success
      message
    }
  }
`;
export const verifyPalencaUberSmsCode = /* GraphQL */ `
  mutation VerifyPalencaUberSmsCode($platform: String, $code: String) {
    verifyPalencaUberSmsCode(platform: $platform, code: $code) {
      success
      message
    }
  }
`;
export const linkPalencaPlatformProfile = /* GraphQL */ `
  mutation LinkPalencaPlatformProfile(
    $platform: String
    $platform_id: String
    $user_id: String
  ) {
    linkPalencaPlatformProfile(
      platform: $platform
      platform_id: $platform_id
      user_id: $user_id
    ) {
      success
      message
    }
  }
`;
export const refreshPlatformEarnings = /* GraphQL */ `
  mutation RefreshPlatformEarnings($platform: String, $user_id: String) {
    refreshPlatformEarnings(platform: $platform, user_id: $user_id) {
      success
      message
    }
  }
`;
export const sendPushToUsers = /* GraphQL */ `
  mutation SendPushToUsers(
    $tokens: [String]
    $title: String
    $subtitle: String
    $message: String
    $data: String
  ) {
    sendPushToUsers(
      tokens: $tokens
      title: $title
      subtitle: $subtitle
      message: $message
      data: $data
    ) {
      success
      message
    }
  }
`;
export const saveNotifications = /* GraphQL */ `
  mutation SaveNotifications(
    $worker_ids: [String]
    $title: String
    $subtitle: String
    $message: String
    $send_push: Boolean
    $type: String
    $route: String
    $sub_route: String
  ) {
    saveNotifications(
      worker_ids: $worker_ids
      title: $title
      subtitle: $subtitle
      message: $message
      send_push: $send_push
      type: $type
      route: $route
      sub_route: $sub_route
    ) {
      success
      message
    }
  }
`;
export const fetchCognitoUsers = /* GraphQL */ `
  mutation FetchCognitoUsers($user_token: String) {
    fetchCognitoUsers(user_token: $user_token) {
      success
      message
    }
  }
`;
export const addPromoterId = /* GraphQL */ `
  mutation AddPromoterId($user_id: String) {
    addPromoterId(user_id: $user_id) {
      success
      message
    }
  }
`;
export const testOnvoFunctions = /* GraphQL */ `
  mutation TestOnvoFunctions {
    testOnvoFunctions {
      success
      message
    }
  }
`;
export const addCardToWorker = /* GraphQL */ `
  mutation AddCardToWorker(
    $card_holder: String
    $card_number: String
    $card_cvc: String
    $card_expiration_month: String
    $card_expiration_year: String
    $billing_name: String
  ) {
    addCardToWorker(
      card_holder: $card_holder
      card_number: $card_number
      card_cvc: $card_cvc
      card_expiration_month: $card_expiration_month
      card_expiration_year: $card_expiration_year
      billing_name: $billing_name
    ) {
      success
      message
    }
  }
`;
export const removeCardToWorker = /* GraphQL */ `
  mutation RemoveCardToWorker($card_id: String) {
    removeCardToWorker(card_id: $card_id) {
      success
      message
    }
  }
`;
export const subscribeWorker = /* GraphQL */ `
  mutation SubscribeWorker($payment_id: String, $type: String) {
    subscribeWorker(payment_id: $payment_id, type: $type) {
      success
      message
    }
  }
`;
export const unsubscribeWorker = /* GraphQL */ `
  mutation UnsubscribeWorker {
    unsubscribeWorker {
      success
      message
    }
  }
`;
export const createOnvoProduct = /* GraphQL */ `
  mutation CreateOnvoProduct($product_name: String) {
    createOnvoProduct(product_name: $product_name) {
      success
      message
    }
  }
`;
export const createOnvoPrice = /* GraphQL */ `
  mutation CreateOnvoPrice(
    $price_amount: Int
    $price_nickname: String
    $product_id: String
  ) {
    createOnvoPrice(
      price_amount: $price_amount
      price_nickname: $price_nickname
      product_id: $product_id
    ) {
      success
      message
    }
  }
`;
export const deleteOnvoWorker = /* GraphQL */ `
  mutation DeleteOnvoWorker($worker_id: String) {
    deleteOnvoWorker(worker_id: $worker_id) {
      success
      message
    }
  }
`;
export const deleteOnvoCustomer = /* GraphQL */ `
  mutation DeleteOnvoCustomer($customer_id: String) {
    deleteOnvoCustomer(customer_id: $customer_id) {
      success
      message
    }
  }
`;
export const getPaymentMethods = /* GraphQL */ `
  mutation GetPaymentMethods {
    getPaymentMethods {
      success
      message
    }
  }
`;
export const updateSubscription = /* GraphQL */ `
  mutation UpdateSubscription($subscription_id: String) {
    updateSubscription(subscription_id: $subscription_id) {
      success
      message
    }
  }
`;
export const listOrganizationLeads = /* GraphQL */ `
  mutation ListOrganizationLeads(
    $organization_id: String
    $status: String
    $limit: Int
    $next_token: String
  ) {
    listOrganizationLeads(
      organization_id: $organization_id
      status: $status
      limit: $limit
      next_token: $next_token
    ) {
      success
      message
      leads {
        id
        type
        product_id
        worker_id
        status
        approved
        assignee
        organization_id
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        nextToken
      }
      nextToken
    }
  }
`;
export const listOrganizationClients = /* GraphQL */ `
  mutation ListOrganizationClients(
    $organization_id: String
    $limit: Int
    $next_token: String
  ) {
    listOrganizationClients(
      organization_id: $organization_id
      limit: $limit
      next_token: $next_token
    ) {
      success
      message
      clients {
        id
        worker_id
        organization_id
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getClientLeases = /* GraphQL */ `
  mutation GetClientLeases($client_id: String) {
    getClientLeases(client_id: $client_id) {
      success
      message
      leases {
        id
        client_id
        product_id
        start_date
        end_date
        status
        amount
        currency
        down_payment
        interest
        term
        tax
        total
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getLeadsStats = /* GraphQL */ `
  mutation GetLeadsStats($organization_id: String) {
    getLeadsStats(organization_id: $organization_id) {
      success
      message
      stats {
        currency
        total_leads
        avg_monthly_earnings
        average_weekly_earnings
        average_rating
        average_trips
      }
      nextToken
    }
  }
`;
export const getClientsStats = /* GraphQL */ `
  mutation GetClientsStats($organization_id: String) {
    getClientsStats(organization_id: $organization_id) {
      success
      message
      stats {
        currency
        total_amount
        amount_pending_collection
        total_clients
        clients_in_debt
        average_client_earnings
        average_trips
      }
      nextToken
    }
  }
`;
export const getInvoiceDetails = /* GraphQL */ `
  mutation GetInvoiceDetails($invoice_id: String, $lease_id: String) {
    getInvoiceDetails(invoice_id: $invoice_id, lease_id: $lease_id) {
      success
      message
      invoice_details {
        model
        brand
        installment_amount
        installment_number
        remaining_installments
        currency
        subtotal
        tax
        total
        invoice_number
        payment_method
        email
      }
      nextToken
    }
  }
`;
export const getWorkerStats = /* GraphQL */ `
  mutation GetWorkerStats($worker_id: String) {
    getWorkerStats(worker_id: $worker_id) {
      success
      message
      stats {
        id
        score
        avg_platform_score
        blacklisted
        average_trips
      }
    }
  }
`;
export const approveLead = /* GraphQL */ `
  mutation ApproveLead($lead_id: String) {
    approveLead(lead_id: $lead_id) {
      success
      message
    }
  }
`;
export const rejectLead = /* GraphQL */ `
  mutation RejectLead($lead_id: String) {
    rejectLead(lead_id: $lead_id) {
      success
      message
    }
  }
`;
export const searchClients = /* GraphQL */ `
  mutation SearchClients($organization_id: String, $search: String) {
    searchClients(organization_id: $organization_id, search: $search) {
      success
      message
      clients {
        id
        worker_id
        organization_id
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const searchLeads = /* GraphQL */ `
  mutation SearchLeads($organization_id: String, $search: String) {
    searchLeads(organization_id: $organization_id, search: $search) {
      success
      message
      leads {
        id
        type
        product_id
        worker_id
        status
        approved
        assignee
        organization_id
        _version
        _deleted
        _lastChangedAt
        createdAt
        updatedAt
        nextToken
      }
      nextToken
    }
  }
`;
export const createPayment = /* GraphQL */ `
  mutation CreatePayment(
    $input: CreatePaymentInput!
    $condition: ModelPaymentConditionInput
  ) {
    createPayment(input: $input, condition: $condition) {
      id
      amount
      currency
      date
      worker_id
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updatePayment = /* GraphQL */ `
  mutation UpdatePayment(
    $input: UpdatePaymentInput!
    $condition: ModelPaymentConditionInput
  ) {
    updatePayment(input: $input, condition: $condition) {
      id
      amount
      currency
      date
      worker_id
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deletePayment = /* GraphQL */ `
  mutation DeletePayment(
    $input: DeletePaymentInput!
    $condition: ModelPaymentConditionInput
  ) {
    deletePayment(input: $input, condition: $condition) {
      id
      amount
      currency
      date
      worker_id
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createOrganization = /* GraphQL */ `
  mutation CreateOrganization(
    $input: CreateOrganizationInput!
    $condition: ModelOrganizationConditionInput
  ) {
    createOrganization(input: $input, condition: $condition) {
      id
      name
      description
      logo
      owner_id
      email
      website
      phone_number
      address
      city
      state
      zip
      locations {
        name
        images
        description
        address
        city
        state
        zip
        latitude
        longitude
        phone_number
        website
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      clients {
        nextToken
        startedAt
      }
      products {
        nextToken
        startedAt
      }
      orders {
        nextToken
        startedAt
      }
    }
  }
`;
export const updateOrganization = /* GraphQL */ `
  mutation UpdateOrganization(
    $input: UpdateOrganizationInput!
    $condition: ModelOrganizationConditionInput
  ) {
    updateOrganization(input: $input, condition: $condition) {
      id
      name
      description
      logo
      owner_id
      email
      website
      phone_number
      address
      city
      state
      zip
      locations {
        name
        images
        description
        address
        city
        state
        zip
        latitude
        longitude
        phone_number
        website
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      clients {
        nextToken
        startedAt
      }
      products {
        nextToken
        startedAt
      }
      orders {
        nextToken
        startedAt
      }
    }
  }
`;
export const deleteOrganization = /* GraphQL */ `
  mutation DeleteOrganization(
    $input: DeleteOrganizationInput!
    $condition: ModelOrganizationConditionInput
  ) {
    deleteOrganization(input: $input, condition: $condition) {
      id
      name
      description
      logo
      owner_id
      email
      website
      phone_number
      address
      city
      state
      zip
      locations {
        name
        images
        description
        address
        city
        state
        zip
        latitude
        longitude
        phone_number
        website
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      clients {
        nextToken
        startedAt
      }
      products {
        nextToken
        startedAt
      }
      orders {
        nextToken
        startedAt
      }
    }
  }
`;
export const createClient = /* GraphQL */ `
  mutation CreateClient(
    $input: CreateClientInput!
    $condition: ModelClientConditionInput
  ) {
    createClient(input: $input, condition: $condition) {
      id
      worker_id
      organization_id
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      leases {
        nextToken
        startedAt
      }
    }
  }
`;
export const updateClient = /* GraphQL */ `
  mutation UpdateClient(
    $input: UpdateClientInput!
    $condition: ModelClientConditionInput
  ) {
    updateClient(input: $input, condition: $condition) {
      id
      worker_id
      organization_id
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      leases {
        nextToken
        startedAt
      }
    }
  }
`;
export const deleteClient = /* GraphQL */ `
  mutation DeleteClient(
    $input: DeleteClientInput!
    $condition: ModelClientConditionInput
  ) {
    deleteClient(input: $input, condition: $condition) {
      id
      worker_id
      organization_id
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      leases {
        nextToken
        startedAt
      }
    }
  }
`;
export const createLease = /* GraphQL */ `
  mutation CreateLease(
    $input: CreateLeaseInput!
    $condition: ModelLeaseConditionInput
  ) {
    createLease(input: $input, condition: $condition) {
      id
      client_id
      product_id
      start_date
      end_date
      status
      amount
      currency
      down_payment
      interest
      term
      tax
      total
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      invoices {
        nextToken
        startedAt
      }
    }
  }
`;
export const updateLease = /* GraphQL */ `
  mutation UpdateLease(
    $input: UpdateLeaseInput!
    $condition: ModelLeaseConditionInput
  ) {
    updateLease(input: $input, condition: $condition) {
      id
      client_id
      product_id
      start_date
      end_date
      status
      amount
      currency
      down_payment
      interest
      term
      tax
      total
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      invoices {
        nextToken
        startedAt
      }
    }
  }
`;
export const deleteLease = /* GraphQL */ `
  mutation DeleteLease(
    $input: DeleteLeaseInput!
    $condition: ModelLeaseConditionInput
  ) {
    deleteLease(input: $input, condition: $condition) {
      id
      client_id
      product_id
      start_date
      end_date
      status
      amount
      currency
      down_payment
      interest
      term
      tax
      total
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      invoices {
        nextToken
        startedAt
      }
    }
  }
`;
export const createInvoice = /* GraphQL */ `
  mutation CreateInvoice(
    $input: CreateInvoiceInput!
    $condition: ModelInvoiceConditionInput
  ) {
    createInvoice(input: $input, condition: $condition) {
      id
      lease_id
      invoice_number
      amount
      payed_amount
      currency
      payment_method
      date
      status
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateInvoice = /* GraphQL */ `
  mutation UpdateInvoice(
    $input: UpdateInvoiceInput!
    $condition: ModelInvoiceConditionInput
  ) {
    updateInvoice(input: $input, condition: $condition) {
      id
      lease_id
      invoice_number
      amount
      payed_amount
      currency
      payment_method
      date
      status
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteInvoice = /* GraphQL */ `
  mutation DeleteInvoice(
    $input: DeleteInvoiceInput!
    $condition: ModelInvoiceConditionInput
  ) {
    deleteInvoice(input: $input, condition: $condition) {
      id
      lease_id
      invoice_number
      amount
      payed_amount
      currency
      payment_method
      date
      status
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createProduct = /* GraphQL */ `
  mutation CreateProduct(
    $input: CreateProductInput!
    $condition: ModelProductConditionInput
  ) {
    createProduct(input: $input, condition: $condition) {
      id
      title
      subtitle
      discount_percent
      price
      original_price
      description
      category
      image
      locations {
        name
        images
        description
        address
        city
        state
        zip
        latitude
        longitude
        phone_number
        website
      }
      blur
      currency
      type
      manufacturer
      model
      year
      contract
      installment
      down_payment
      mileage
      value
      interest
      technology
      term_length
      term
      min_term
      max_term
      tax
      organization_id
      createdAt
      pickup_address
      pickup_lat
      pickup_long
      dropoff_address
      dropoff_lat
      dropoff_long
      miles_per_gallon
      transmission
      fuel_type
      door_count
      seat_count
      engine
      is_insured
      min_monthly_earnings
      min_rating
      min_trips_count
      notes
      published
      _version
      _deleted
      _lastChangedAt
      updatedAt
    }
  }
`;
export const updateProduct = /* GraphQL */ `
  mutation UpdateProduct(
    $input: UpdateProductInput!
    $condition: ModelProductConditionInput
  ) {
    updateProduct(input: $input, condition: $condition) {
      id
      title
      subtitle
      discount_percent
      price
      original_price
      description
      category
      image
      locations {
        name
        images
        description
        address
        city
        state
        zip
        latitude
        longitude
        phone_number
        website
      }
      blur
      currency
      type
      manufacturer
      model
      year
      contract
      installment
      down_payment
      mileage
      value
      interest
      technology
      term_length
      term
      min_term
      max_term
      tax
      organization_id
      createdAt
      pickup_address
      pickup_lat
      pickup_long
      dropoff_address
      dropoff_lat
      dropoff_long
      miles_per_gallon
      transmission
      fuel_type
      door_count
      seat_count
      engine
      is_insured
      min_monthly_earnings
      min_rating
      min_trips_count
      notes
      published
      _version
      _deleted
      _lastChangedAt
      updatedAt
    }
  }
`;
export const deleteProduct = /* GraphQL */ `
  mutation DeleteProduct(
    $input: DeleteProductInput!
    $condition: ModelProductConditionInput
  ) {
    deleteProduct(input: $input, condition: $condition) {
      id
      title
      subtitle
      discount_percent
      price
      original_price
      description
      category
      image
      locations {
        name
        images
        description
        address
        city
        state
        zip
        latitude
        longitude
        phone_number
        website
      }
      blur
      currency
      type
      manufacturer
      model
      year
      contract
      installment
      down_payment
      mileage
      value
      interest
      technology
      term_length
      term
      min_term
      max_term
      tax
      organization_id
      createdAt
      pickup_address
      pickup_lat
      pickup_long
      dropoff_address
      dropoff_lat
      dropoff_long
      miles_per_gallon
      transmission
      fuel_type
      door_count
      seat_count
      engine
      is_insured
      min_monthly_earnings
      min_rating
      min_trips_count
      notes
      published
      _version
      _deleted
      _lastChangedAt
      updatedAt
    }
  }
`;
export const createOrder = /* GraphQL */ `
  mutation CreateOrder(
    $input: CreateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    createOrder(input: $input, condition: $condition) {
      id
      type
      product_id
      worker_id
      status
      approved
      assignee
      organization_id
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
    }
  }
`;
export const updateOrder = /* GraphQL */ `
  mutation UpdateOrder(
    $input: UpdateOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    updateOrder(input: $input, condition: $condition) {
      id
      type
      product_id
      worker_id
      status
      approved
      assignee
      organization_id
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
    }
  }
`;
export const deleteOrder = /* GraphQL */ `
  mutation DeleteOrder(
    $input: DeleteOrderInput!
    $condition: ModelOrderConditionInput
  ) {
    deleteOrder(input: $input, condition: $condition) {
      id
      type
      product_id
      worker_id
      status
      approved
      assignee
      organization_id
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
    }
  }
`;
export const createEvent = /* GraphQL */ `
  mutation CreateEvent(
    $input: CreateEventInput!
    $condition: ModelEventConditionInput
  ) {
    createEvent(input: $input, condition: $condition) {
      id
      platform
      event_id
      type
      start_date
      timezone
      duration
      distance
      distance_unit
      pickup_address
      dropoff_address
      amount
      currency
      worker_id
      platform_profile_id
      other_info
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateEvent = /* GraphQL */ `
  mutation UpdateEvent(
    $input: UpdateEventInput!
    $condition: ModelEventConditionInput
  ) {
    updateEvent(input: $input, condition: $condition) {
      id
      platform
      event_id
      type
      start_date
      timezone
      duration
      distance
      distance_unit
      pickup_address
      dropoff_address
      amount
      currency
      worker_id
      platform_profile_id
      other_info
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteEvent = /* GraphQL */ `
  mutation DeleteEvent(
    $input: DeleteEventInput!
    $condition: ModelEventConditionInput
  ) {
    deleteEvent(input: $input, condition: $condition) {
      id
      platform
      event_id
      type
      start_date
      timezone
      duration
      distance
      distance_unit
      pickup_address
      dropoff_address
      amount
      currency
      worker_id
      platform_profile_id
      other_info
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createConnectionAttempt = /* GraphQL */ `
  mutation CreateConnectionAttempt(
    $input: CreateConnectionAttemptInput!
    $condition: ModelConnectionAttemptConditionInput
  ) {
    createConnectionAttempt(input: $input, condition: $condition) {
      id
      type
      description
      platform
      status
      worker_id
      data
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateConnectionAttempt = /* GraphQL */ `
  mutation UpdateConnectionAttempt(
    $input: UpdateConnectionAttemptInput!
    $condition: ModelConnectionAttemptConditionInput
  ) {
    updateConnectionAttempt(input: $input, condition: $condition) {
      id
      type
      description
      platform
      status
      worker_id
      data
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteConnectionAttempt = /* GraphQL */ `
  mutation DeleteConnectionAttempt(
    $input: DeleteConnectionAttemptInput!
    $condition: ModelConnectionAttemptConditionInput
  ) {
    deleteConnectionAttempt(input: $input, condition: $condition) {
      id
      type
      description
      platform
      status
      worker_id
      data
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createEarning = /* GraphQL */ `
  mutation CreateEarning(
    $input: CreateEarningInput!
    $condition: ModelEarningConditionInput
  ) {
    createEarning(input: $input, condition: $condition) {
      id
      datestr
      earnings
      currency
      count_trips
      worker_id
      online_hours
      ontrip_hours
      driver_canceled_trips
      acceptance_rate
      loyalty_points
      tips
      net_fare
      cash_received
      promotions
      taxes
      online
      trips
      points
      platform_profile_id
      other_info
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateEarning = /* GraphQL */ `
  mutation UpdateEarning(
    $input: UpdateEarningInput!
    $condition: ModelEarningConditionInput
  ) {
    updateEarning(input: $input, condition: $condition) {
      id
      datestr
      earnings
      currency
      count_trips
      worker_id
      online_hours
      ontrip_hours
      driver_canceled_trips
      acceptance_rate
      loyalty_points
      tips
      net_fare
      cash_received
      promotions
      taxes
      online
      trips
      points
      platform_profile_id
      other_info
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteEarning = /* GraphQL */ `
  mutation DeleteEarning(
    $input: DeleteEarningInput!
    $condition: ModelEarningConditionInput
  ) {
    deleteEarning(input: $input, condition: $condition) {
      id
      datestr
      earnings
      currency
      count_trips
      worker_id
      online_hours
      ontrip_hours
      driver_canceled_trips
      acceptance_rate
      loyalty_points
      tips
      net_fare
      cash_received
      promotions
      taxes
      online
      trips
      points
      platform_profile_id
      other_info
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createPlatformProfile = /* GraphQL */ `
  mutation CreatePlatformProfile(
    $input: CreatePlatformProfileInput!
    $condition: ModelPlatformProfileConditionInput
  ) {
    createPlatformProfile(input: $input, condition: $condition) {
      id
      worker_id
      account_id
      platform
      country
      first_name
      last_name
      email
      phone_prefix
      phone_number
      birthday
      city_name
      url_picture
      id_type
      id_number
      rating
      lifetime_trips
      time_since_ft
      level_name
      level_number
      acceptance_rate
      vehicle_info {
        type
        brand
        model
        year
        license_plate
        license_plate_state
        state
        vin
      }
      gender
      cancelation_rate
      thumbs_up
      bank_info
      palenca_worker_id
      last_fetched_data
      last_fetched_events
      can_fetch_data
      activation_status
      employment_info
      other_info
      metrics_info
      status
      deleted
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      events {
        nextToken
        startedAt
      }
      earnings {
        nextToken
        startedAt
      }
    }
  }
`;
export const updatePlatformProfile = /* GraphQL */ `
  mutation UpdatePlatformProfile(
    $input: UpdatePlatformProfileInput!
    $condition: ModelPlatformProfileConditionInput
  ) {
    updatePlatformProfile(input: $input, condition: $condition) {
      id
      worker_id
      account_id
      platform
      country
      first_name
      last_name
      email
      phone_prefix
      phone_number
      birthday
      city_name
      url_picture
      id_type
      id_number
      rating
      lifetime_trips
      time_since_ft
      level_name
      level_number
      acceptance_rate
      vehicle_info {
        type
        brand
        model
        year
        license_plate
        license_plate_state
        state
        vin
      }
      gender
      cancelation_rate
      thumbs_up
      bank_info
      palenca_worker_id
      last_fetched_data
      last_fetched_events
      can_fetch_data
      activation_status
      employment_info
      other_info
      metrics_info
      status
      deleted
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      events {
        nextToken
        startedAt
      }
      earnings {
        nextToken
        startedAt
      }
    }
  }
`;
export const deletePlatformProfile = /* GraphQL */ `
  mutation DeletePlatformProfile(
    $input: DeletePlatformProfileInput!
    $condition: ModelPlatformProfileConditionInput
  ) {
    deletePlatformProfile(input: $input, condition: $condition) {
      id
      worker_id
      account_id
      platform
      country
      first_name
      last_name
      email
      phone_prefix
      phone_number
      birthday
      city_name
      url_picture
      id_type
      id_number
      rating
      lifetime_trips
      time_since_ft
      level_name
      level_number
      acceptance_rate
      vehicle_info {
        type
        brand
        model
        year
        license_plate
        license_plate_state
        state
        vin
      }
      gender
      cancelation_rate
      thumbs_up
      bank_info
      palenca_worker_id
      last_fetched_data
      last_fetched_events
      can_fetch_data
      activation_status
      employment_info
      other_info
      metrics_info
      status
      deleted
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      events {
        nextToken
        startedAt
      }
      earnings {
        nextToken
        startedAt
      }
    }
  }
`;
export const createWorker = /* GraphQL */ `
  mutation CreateWorker(
    $input: CreateWorkerInput!
    $condition: ModelWorkerConditionInput
  ) {
    createWorker(input: $input, condition: $condition) {
      id
      image
      email
      given_name
      middle_name
      family_name
      second_family_name
      birthday
      type
      phone_number
      country
      country_id
      address
      identifier
      identifier_type
      worker_kyc {
        type
        first_names
        last_names
        id_type
        id_number
        birth_country
        birthday
        gender
        civil_status
        email
        phone_number
        address
        percentage
      }
      referral_id
      promoter_id
      is_subscribed
      last_payment_date
      next_payment_date
      devices {
        device_token
        device_platform
        send_push
      }
      credit_cards {
        token
        card_holder
        last_digits
        network
      }
      notifications {
        title
        subtitle
        message
        send_push
        sent_by
        type
        route
        sub_route
        read
        created_at
      }
      subscription {
        onvo_subscription_id
        type
        amount
        currency
        end_date
      }
      loymark_account_id
      loymark_account_number
      onboarding_platforms
      onvo_customer_id
      palenca_user_id
      organization_id
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      payments {
        nextToken
        startedAt
      }
      events {
        nextToken
        startedAt
      }
      earnings {
        nextToken
        startedAt
      }
      earningsByDate {
        nextToken
        startedAt
      }
      platform_profiles {
        nextToken
        startedAt
      }
    }
  }
`;
export const updateWorker = /* GraphQL */ `
  mutation UpdateWorker(
    $input: UpdateWorkerInput!
    $condition: ModelWorkerConditionInput
  ) {
    updateWorker(input: $input, condition: $condition) {
      id
      image
      email
      given_name
      middle_name
      family_name
      second_family_name
      birthday
      type
      phone_number
      country
      country_id
      address
      identifier
      identifier_type
      worker_kyc {
        type
        first_names
        last_names
        id_type
        id_number
        birth_country
        birthday
        gender
        civil_status
        email
        phone_number
        address
        percentage
      }
      referral_id
      promoter_id
      is_subscribed
      last_payment_date
      next_payment_date
      devices {
        device_token
        device_platform
        send_push
      }
      credit_cards {
        token
        card_holder
        last_digits
        network
      }
      notifications {
        title
        subtitle
        message
        send_push
        sent_by
        type
        route
        sub_route
        read
        created_at
      }
      subscription {
        onvo_subscription_id
        type
        amount
        currency
        end_date
      }
      loymark_account_id
      loymark_account_number
      onboarding_platforms
      onvo_customer_id
      palenca_user_id
      organization_id
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      payments {
        nextToken
        startedAt
      }
      events {
        nextToken
        startedAt
      }
      earnings {
        nextToken
        startedAt
      }
      earningsByDate {
        nextToken
        startedAt
      }
      platform_profiles {
        nextToken
        startedAt
      }
    }
  }
`;
export const deleteWorker = /* GraphQL */ `
  mutation DeleteWorker(
    $input: DeleteWorkerInput!
    $condition: ModelWorkerConditionInput
  ) {
    deleteWorker(input: $input, condition: $condition) {
      id
      image
      email
      given_name
      middle_name
      family_name
      second_family_name
      birthday
      type
      phone_number
      country
      country_id
      address
      identifier
      identifier_type
      worker_kyc {
        type
        first_names
        last_names
        id_type
        id_number
        birth_country
        birthday
        gender
        civil_status
        email
        phone_number
        address
        percentage
      }
      referral_id
      promoter_id
      is_subscribed
      last_payment_date
      next_payment_date
      devices {
        device_token
        device_platform
        send_push
      }
      credit_cards {
        token
        card_holder
        last_digits
        network
      }
      notifications {
        title
        subtitle
        message
        send_push
        sent_by
        type
        route
        sub_route
        read
        created_at
      }
      subscription {
        onvo_subscription_id
        type
        amount
        currency
        end_date
      }
      loymark_account_id
      loymark_account_number
      onboarding_platforms
      onvo_customer_id
      palenca_user_id
      organization_id
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
      payments {
        nextToken
        startedAt
      }
      events {
        nextToken
        startedAt
      }
      earnings {
        nextToken
        startedAt
      }
      earningsByDate {
        nextToken
        startedAt
      }
      platform_profiles {
        nextToken
        startedAt
      }
    }
  }
`;
export const createCupon = /* GraphQL */ `
  mutation CreateCupon(
    $input: CreateCuponInput!
    $condition: ModelCuponConditionInput
  ) {
    createCupon(input: $input, condition: $condition) {
      id
      title
      subtitle
      discount_percent
      price
      original_price
      description
      category
      image
      locations {
        name
        images
        description
        address
        city
        state
        zip
        latitude
        longitude
        phone_number
        website
      }
      blur
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateCupon = /* GraphQL */ `
  mutation UpdateCupon(
    $input: UpdateCuponInput!
    $condition: ModelCuponConditionInput
  ) {
    updateCupon(input: $input, condition: $condition) {
      id
      title
      subtitle
      discount_percent
      price
      original_price
      description
      category
      image
      locations {
        name
        images
        description
        address
        city
        state
        zip
        latitude
        longitude
        phone_number
        website
      }
      blur
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteCupon = /* GraphQL */ `
  mutation DeleteCupon(
    $input: DeleteCuponInput!
    $condition: ModelCuponConditionInput
  ) {
    deleteCupon(input: $input, condition: $condition) {
      id
      title
      subtitle
      discount_percent
      price
      original_price
      description
      category
      image
      locations {
        name
        images
        description
        address
        city
        state
        zip
        latitude
        longitude
        phone_number
        website
      }
      blur
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createSpot = /* GraphQL */ `
  mutation CreateSpot(
    $input: CreateSpotInput!
    $condition: ModelSpotConditionInput
  ) {
    createSpot(input: $input, condition: $condition) {
      id
      title
      description
      image
      hours {
        day
        start_time
        end_time
        description
      }
      location {
        name
        images
        description
        address
        city
        state
        zip
        latitude
        longitude
        phone_number
        website
      }
      address
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateSpot = /* GraphQL */ `
  mutation UpdateSpot(
    $input: UpdateSpotInput!
    $condition: ModelSpotConditionInput
  ) {
    updateSpot(input: $input, condition: $condition) {
      id
      title
      description
      image
      hours {
        day
        start_time
        end_time
        description
      }
      location {
        name
        images
        description
        address
        city
        state
        zip
        latitude
        longitude
        phone_number
        website
      }
      address
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteSpot = /* GraphQL */ `
  mutation DeleteSpot(
    $input: DeleteSpotInput!
    $condition: ModelSpotConditionInput
  ) {
    deleteSpot(input: $input, condition: $condition) {
      id
      title
      description
      image
      hours {
        day
        start_time
        end_time
        description
      }
      location {
        name
        images
        description
        address
        city
        state
        zip
        latitude
        longitude
        phone_number
        website
      }
      address
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createAppSetting = /* GraphQL */ `
  mutation CreateAppSetting(
    $input: CreateAppSettingInput!
    $condition: ModelAppSettingConditionInput
  ) {
    createAppSetting(input: $input, condition: $condition) {
      id
      loymark_token
      loymark_session_id
      platform_availability {
        platform
        online
      }
      module_availability {
        module
        online
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateAppSetting = /* GraphQL */ `
  mutation UpdateAppSetting(
    $input: UpdateAppSettingInput!
    $condition: ModelAppSettingConditionInput
  ) {
    updateAppSetting(input: $input, condition: $condition) {
      id
      loymark_token
      loymark_session_id
      platform_availability {
        platform
        online
      }
      module_availability {
        module
        online
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteAppSetting = /* GraphQL */ `
  mutation DeleteAppSetting(
    $input: DeleteAppSettingInput!
    $condition: ModelAppSettingConditionInput
  ) {
    deleteAppSetting(input: $input, condition: $condition) {
      id
      loymark_token
      loymark_session_id
      platform_availability {
        platform
        online
      }
      module_availability {
        module
        online
      }
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createInAppRating = /* GraphQL */ `
  mutation CreateInAppRating(
    $input: CreateInAppRatingInput!
    $condition: ModelInAppRatingConditionInput
  ) {
    createInAppRating(input: $input, condition: $condition) {
      id
      rating
      content
      worker_id
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
    }
  }
`;
export const updateInAppRating = /* GraphQL */ `
  mutation UpdateInAppRating(
    $input: UpdateInAppRatingInput!
    $condition: ModelInAppRatingConditionInput
  ) {
    updateInAppRating(input: $input, condition: $condition) {
      id
      rating
      content
      worker_id
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
    }
  }
`;
export const deleteInAppRating = /* GraphQL */ `
  mutation DeleteInAppRating(
    $input: DeleteInAppRatingInput!
    $condition: ModelInAppRatingConditionInput
  ) {
    deleteInAppRating(input: $input, condition: $condition) {
      id
      rating
      content
      worker_id
      createdAt
      _version
      _deleted
      _lastChangedAt
      updatedAt
    }
  }
`;
export const listOrganizationProducts = /* GraphQL */ `
  mutation ListOrganizationProducts(
    $organization_id: String
    $limit: Int
    $next_token: String
    $category: String
  ) {
    listOrganizationProducts(
      organization_id: $organization_id
      limit: $limit
      next_token: $next_token
      category: $category
    ) {
      success
      message
      products {
        id
        title
        subtitle
        discount_percent
        price
        original_price
        description
        category
        image
        blur
        currency
        type
        manufacturer
        model
        year
        contract
        installment
        down_payment
        mileage
        value
        interest
        technology
        term_length
        term
        min_term
        max_term
        tax
        organization_id
        createdAt
        pickup_address
        pickup_lat
        pickup_long
        dropoff_address
        dropoff_lat
        dropoff_long
        miles_per_gallon
        transmission
        fuel_type
        door_count
        seat_count
        engine
        is_insured
        min_monthly_earnings
        min_rating
        min_trips_count
        notes
        published
        _version
        _deleted
        _lastChangedAt
        updatedAt
      }
      nextToken
    }
  }
`;
