import { loadLink, PalencaLinkReact } from '@palenca/palenca-link';
import { useCallback } from 'react';

// Make sure to call `loadLink` outside of a component’s render to avoid recreating the object
const linkPromise = loadLink('public_ce13_4d9c_9328_36307f7c4fa5', 'f7133847-3da0-4ae0-839c-997a6929b1df');

const PalencaLink = () => {
  const handleEvent = useCallback((event: object) => {
    console.log(event);
  }, []);

  const options = {
    configuration: {
      hideConsent: true,
      country: 'mx',
    },
    appearance: {
      primaryColor: '#ea4c89',
      borderRadius: '999px',
    },
  };

  return (
      <div style={{height: 600}}>
            <PalencaLinkReact
              link={linkPromise}
              options={options}
              onEvent={handleEvent}
            />
      </div>
  );
};

export default PalencaLink;
