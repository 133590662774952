import React, { useRef, useEffect } from 'react';
import { fetchCognitoUsers } from '../graphql/mutations';
import { Auth, API , graphqlOperation} from 'aws-amplify';
import { Toast } from 'primereact/toast';

function Home() {
    const toast = useRef(null);

    const getUsers = async () => {
        const token = `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`;
        console.log('token', token);
        const usersData = await API.graphql(graphqlOperation(fetchCognitoUsers, { user_token: token }));
        console.log(usersData);
    }

    useEffect(() => {
        getUsers();
    }, [])

    return (
        <div className="Home">
            <Toast ref={toast} />
            <h2>Home</h2>
        </div>
    );
}

export default Home;