import React, { useEffect, useState, useRef } from 'react'
import { API, graphqlOperation } from 'aws-amplify'
import { updateCupon } from '../graphql/mutations';
import { getCupon } from '../graphql/queries';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import {
  useParams
} from "react-router-dom";
import { classNames } from 'primereact/utils';


function Locations() {

  const { id } = useParams();
  const [locations, setLocations] = useState([]);

  const emptyLocation = {
    address: "",
    city: "",
    description: "",
    latitude: "",
    longitude: "",
    name: "",
    state: "",
    zip: "",
  }
  const [locationDialog, setLocationDialog] = useState(false);
  const [cupon, setCupon] = useState();
  const [location, setLocation] = useState(emptyLocation);
  const [edit, setEdit] = useState(false);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    fetchLocations();
   }, [])


  async function fetchLocations() {
    try {
      const cuponData=await API.graphql(graphqlOperation(getCupon, {id: id}));
      const _locations= cuponData.data.getCupon.locations;  
      for (var i = 0;i<_locations.length;i++){
        _locations[i].id = i;
      }
      console.log(_locations);
      setLocations(_locations);
      setCupon(cuponData.data.getCupon);
    } catch (err) { 
      console.log('error fetching locations');
      console.log(err);
    }
  };



  const openNew = () => {
    setEdit(false);
    setLocationDialog(true);
  }

  const addLocation = async () => {    
    setSubmitted(true);
    if(!location.name || !location.city || !location.longitude || !location.latitude){
      return 
    }
    try {
      const _locations = locations.map(({id, ...item }) => item);
      _locations.push(location);
      const _cupon = {
        id: cupon.id,
        title: cupon.title,
        category: cupon.category,
        price: cupon.price,
        original_price: cupon.original_price,
        discount_percent: cupon.discount_percent,
        description: cupon.description,
        _version: cupon._version,
        locations:_locations
        }; 
      console.log(_cupon);
      await API.graphql(graphqlOperation(updateCupon, {input: _cupon}));
      setLocationDialog(false);
      setLocation(emptyLocation);
      setSubmitted(false);
      fetchLocations();
    } catch (err) {
      console.log('error creating location:', err)
    }      
  }

  const hideDialog = () => {
    setLocation(emptyLocation);
    setLocationDialog(false);
    setSubmitted(false);
  }


  const editLocation = async (rowData) => {
    console.log(rowData);
    setLocation(rowData);
    setSubmitted(false);
    setEdit(true);
    setLocationDialog(true);
  }

  const updateSelectedLocation = async () => {
    setSubmitted(true);
    if(!location.name || !location.city || !location.longitude || !location.latitude){
      return 
    }
    try {
      let _location = {
        address: location.address,
        city: location.city,
        description: location.description,
        latitude: location.latitude,
        longitude: location.longitude,
        name: location.name,
        state: location.state,
        zip: location.zip,
      }
      let _locations=locations.map(({id, ...item }) => item);;
      _locations.splice(location.id,1,_location);
      const _cupon = {
        id: cupon.id,
        title: cupon.title,
        category: cupon.category,
        price: cupon.price,
        original_price: cupon.original_price,
        discount_percent: cupon.discount_percent,
        description: cupon.description,
        _version: cupon._version,
        locations:_locations
        };

      console.log(_cupon);

      setLocation(emptyLocation);
      setLocationDialog(false);
      await API.graphql(graphqlOperation(updateCupon, {input: _cupon}))
      fetchLocations();
      setSubmitted(false);
    } catch (err) {
      console.log('error updating cupon:', err)
    } 
  }

  const confirmDeleteLocation = async (id) => {
    try {      
      let _locations=locations.map(({id, ...item }) => item);
      _locations.splice(id, 1);
      const _cupon = {
        id: cupon.id,
        title: cupon.title,
        category: cupon.category,
        price: cupon.price,
        original_price: cupon.original_price,
        discount_percent: cupon.discount_percent,
        description: cupon.description,
        _version: cupon._version,
        locations:_locations
        }; 
      console.log(_cupon);
      await API.graphql(graphqlOperation(updateCupon, {input: _cupon}));
      fetchLocations();    
    }catch(err){
      console.log('error deleting location:', err)
    }
    }



  const onInputChange = (e, name) => {
      const val = (e.target && e.target.value);
      let _location = {...location};
      _location[`${name}`] = val;
      setLocation(_location);
  }


  const leftToolbarTemplate = () => {
    return (
        <React.Fragment>
            <Button label="New" icon="pi pi-plus" className="p-button bg-primary mr-2" onClick={openNew} />
        </React.Fragment>
    )
}


  const actionBodyTemplate = (rowData) => {
      return (
          <React.Fragment>
              <Button icon="pi pi-pencil" className="p-button-rounded bg-primary mr-2 " onClick={() => editLocation(rowData)} />
              <Button icon="pi pi-trash" className="p-button-rounded p-button-warning mr-2" onClick={() => confirmDeleteLocation(rowData.id)} />
          </React.Fragment>
      );
  }

  const header = (
      <div className="table-header">
          <h3 className="mx-0 my-1">Locations</h3>
          <span className="p-input-icon-left">
              <i className="pi pi-search" />
              <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
          </span>
      </div>
  );
  const locationDialogFooter = (
      <React.Fragment>
          <Button label="Cancel" icon="pi pi-times" className="p-button-text c-primary" onClick={hideDialog} />
          {!edit && <Button label="Save" icon="pi pi-check" className="p-button-text c-primary" onClick={addLocation} />}
          {edit &&<Button label="Save" icon="pi pi-check" className="p-button-text c-primary" onClick={()=> updateSelectedLocation()} />}
      </React.Fragment>
  );
  


  return (
      <div className="datatable-crud-demo">
      <div className="card">
          <Toolbar className="mb-4" left={leftToolbarTemplate}></Toolbar>

          <DataTable  value={locations}
                dataKey="id" paginator rows={10} rowsPerPageOptions={[ 10, 25, 50]}
                paginatorTemplate="PrevPageLink PageLinks NextPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} locations"
              globalFilter={globalFilter} header={header} responsiveLayout="scroll">
              <Column field="name" header="Name" sortable style={{ minWidth: '8rem' }}></Column>  
              <Column field="city" header="City" sortable style={{ minWidth: '8rem' }}></Column>
              <Column field="latitude" header="Latitude" sortable style={{ minWidth: '8rem' }}></Column>
              <Column field="longitude" header="Longitude" style={{ minWidth: '8rem' }} ></Column>
              <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: '8rem' }}></Column>
          </DataTable>
          
      </div>

      <Dialog visible={locationDialog} style={{ width: '450px' }} header="Location" modal className="p-fluid" footer={locationDialogFooter} onHide={hideDialog}>
          <div className="field">
              <label htmlFor="name">Name</label>
              <InputText id="name" value={location.name} onChange={(e) => onInputChange(e, 'name')} className={classNames({ 'p-invalid': submitted && !location.name })} />
              {submitted && !location.name && <small className="p-error">Name is required.</small>}
          </div>
          <div className="field">
              <label htmlFor="description">Description*</label>
              <InputTextarea id="description" value={location.description} onChange={(e) => onInputChange(e, 'description')} required rows={3} cols={20} />
          </div>

          <div className="formgrid grid">
              <div className="field col">
                  <label htmlFor="state">State*</label>
                  <InputText id="state" value={location.state} onChange={(e) => onInputChange(e, 'state')}/>
              </div>
              <div className="field col">
                  <label htmlFor="city">City</label>
                  <InputText id="city" value={location.city} onChange={(e) => onInputChange(e, 'city')} className={classNames({ 'p-invalid': submitted && !location.city })} />
                  {submitted && !location.city && <small className="p-error">City is required.</small>}

              </div>
          </div>
          <div className="formgrid grid">
              <div className="field col">
                  <label htmlFor="zip">Zip*</label>
                  <InputText id="zip" value={location.zip} onChange={(e) => onInputChange(e, 'zip')} />
              </div>
              <div className="field col">
                  <label htmlFor="address">Address*</label>
                  <InputText id="address" value={location.address} onChange={(e) => onInputChange(e, 'address')} />
              </div>
          </div>          
          <div className="formgrid grid">
              <div className="field col">
                  <label htmlFor="latitude">Latitude</label>
                  <InputText id="latitude" value={location.latitude} onChange={(e) => onInputChange(e, 'latitude')} className={classNames({ 'p-invalid': submitted && !location.latitude })} />
                  {submitted && !location.latitude && <small className="p-error">Latitude is required.</small>}
              </div>
              <div className="field col">
                  <label htmlFor="longitude">Longitude</label>
                  <InputText id="longitude" value={location.longitude} onChange={(e) => onInputChange(e, 'longitude')} className={classNames({ 'p-invalid': submitted && !location.longitude })} />
                  {submitted && !location.longitude && <small className="p-error">Longitude is required.</small>}
              </div>
          </div>

      </Dialog>

  </div>
  );
}

export default Locations;
